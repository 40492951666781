import { init } from './simpler-redux';

import * as models from './models';
import { models as apiModels } from './models/async/backend-api';

const allModels = Object.assign({}, models, apiModels);

const store = init(allModels);

export default store;
