import PropTypes from 'prop-types';
import {useTranslation} from 'react-i18next';
import PatientAvatar from '@/components/business/analytics/patient/common/patient-avatar.jsx';
import {useState} from 'react';
import {colorPalette} from '@/themes/darkmode.js';
import styled from 'styled-components';
import ComponentErrorMessage from '@/components/widgets/component-error-message.tsx';

const PatientSkinTypeTooltip = ({skinType, selected, onSelected}) => {
    const { t } = useTranslation();
    const [showTooltip, setShowTooltip] = useState(false);

    const handleOverButton = () => {
        setShowTooltip(true);
    };

    const handleLeaveButton = () => {
        setShowTooltip(false);
    };

    if(skinType) {
        return (
            <StyledWrapper>
                <button
                    type="button"
                    key={`patientChild-skinType-button-${skinType}`}
                    className={`patientChild-skinType-button${(selected) ? ' selected': ''}`}
                    onClick={() => onSelected(skinType)}
                    onMouseOver={handleOverButton}
                    onMouseLeave={handleLeaveButton}
                >
                    <PatientAvatar skinType={skinType} />

                    {showTooltip &&
                        <div className={`skinType-tooltip-overlay animate__animated animate__fadeIn animate__faster ${(skinType <= 3) ? 'bottomLeft' : 'bottomRight'}`}>
                            <h4>{t(['Skin type title', 'patient.skinTypeTitle.skinType_' + skinType])}</h4>
                            <p>{t(['Skin type text', 'patient.skinTypeText.skinType_' + skinType])}</p>
                        </div>
                    }
                </button>
            </StyledWrapper>
        );
    } else {
        return <ComponentErrorMessage component="PatientSkinTypeTooltip" />;
    }
};

PatientSkinTypeTooltip.propTypes = {
    skinType: PropTypes.number.isRequired,
    selected: PropTypes.bool,
    onSelected: PropTypes.func,
};

//language=SCSS
const StyledWrapper = styled.div`
& {
    display:inline-block;
    margin-bottom: 10px;
    margin-right: 10px;
    
    button {
        position:relative;
        width: 80px;
        height: 80px;
        border-radius: 5px;
        color: ${colorPalette.clearColor};
        border: 2px solid ${colorPalette.clearColor};
        background-color: ${colorPalette.clearColor};
        img {
            width: 80px;
        }
        &:hover, &.selected {
            outline: none;
            border-color: ${colorPalette.activeColor};
        }
    }
    
    .skinType-tooltip-overlay {
        background:${colorPalette.thirdBackground};
        padding: 20px;
        border-radius: 5px;
        z-index: 1050;
        position: absolute;
        left: -2px;
        top: 130%;
        color: ${colorPalette.frontColor};
        width: 300px;
        text-align:left;
        h4 {
            font-size: 14px;
        }
        p {
            font-size: 12px;
            margin-bottom:0;
        }
        &::after {
            content: '';
            position: absolute;
            display: block;
            width: 0;
            left: 25px;
            top: 0;
            border: 8px solid transparent;
            border-top: 0;
            border-bottom: 10px solid ${colorPalette.thirdBackground};
            transform: translate(-50%, -100%);
        }
        &.bottomRight {
            right: -2px;
            left: auto;
        }
        &.bottomRight::after {
            left: auto;
            right: 25px;
        }
    }
}`;

export default PatientSkinTypeTooltip;
