import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import {colorPalette} from '@/themes/darkmode.js';

class InfoMessage extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        infoMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
        text: PropTypes.string,
    };

    render() {
        const props = this.props;

        return (
            <div className={`info-message ${props.className}`}>
                {props.infoMessage}<br />
                {props.text}
            </div>
        );
    }
}

//language=SCSS
InfoMessage = Styled(InfoMessage)`
& {
    background-color:${colorPalette.thirdBackground};
    border-radius: 10px;
    border-left: 10px solid ${colorPalette.activeColor};
    padding: 10px 15px;
    margin: 20px 0;
}
`;

export { InfoMessage };
