import React from 'react';
import Styled from 'styled-components';
import smallScreenBackground from '@/public/images/small-screen-background.svg';
import {colorPalette} from '@/themes/darkmode.js';
import {colors} from '@/themes/darkmode.js';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';

class OutdatedBrowserView extends React.PureComponent {
    static propTypes = {
        t: PropTypes.func,
        className: PropTypes.string,
    };

    render() {
        const props = this.props;
        const { t } = this.props;

        return (
            <div className={props.className}>
                <div>
                    <p className="info-message">
                        {t(['You are currently using an outdated browser.', 'outdatedBrowser.title'])}<br />
                        {t(['Gabi Analytics is available on modern browsers.', 'outdatedBrowser.gabi'])}
                    </p>
                    <a className="update-link" href="https://browsehappy.com">{t(['Update my browser', 'outdatedBrowser.update'])}</a>
                </div>
                <img src={smallScreenBackground} alt={t(['Computer on a desk with Gabi logo on the screen.', 'outdatedBrowser.alt'])} />
            </div>
        );
    }
}
//language=SCSS
OutdatedBrowserView = Styled(OutdatedBrowserView)`
& {
    height:100vh;
    padding: 50px;
    text-align:center;
    
    p.info-message {
        text-align:left;
        width:100%;
        max-width: 500px;
        margin:0 auto;
    }
    
    img {
        width:100%;
        max-width:500px;
        margin-top:25px;
    }
    
    .update-link {
        margin-top:20px;
        display:inline-block;
        background:${colors.buttonCreate.background};
        padding:10px;
        color:${colorPalette.frontColor};
        border-radius:5px;
        &:hover {
            background:${colors.buttonCreate.backgroundHover};
            text-decoration: none;
        }
    }
}
`;

export default withTranslation()(OutdatedBrowserView);
