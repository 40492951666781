import { model } from '@/store/simpler-redux';

export default model({
    name: 'debugEnabled',
    state: false,
    reducers: {
        toggle: (state) => {
            return !state;
        },
    },
    actions: {
    },
});
