import {colorPalette} from '@/themes/darkmode.js';
import errorIcon from '@/public/icons/error-icon.svg';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { ReactNode } from 'react';

const ComponentErrorMessage = ({ component }: { component: ReactNode }) => {

    if(component) {
        return (
            <StyledWrapper className="componentPropValidationError">
                Error: Component <strong>{component}</strong> props are not valid.
            </StyledWrapper>
        );
    } else {
        return (
            <StyledWrapper className="componentPropValidationError">
                Error: Component props are not valid.
            </StyledWrapper>
        );
    }
};

ComponentErrorMessage.propTypes = {
    component: PropTypes.string,
};

//language=SCSS
const StyledWrapper = styled.div`
  && {
      color: ${colorPalette.frontColor};
      padding: 10px 10px 10px 60px;
      border-radius: 5px;
      background-color:${colorPalette.danger};
      background-image: url(${errorIcon});
      background-repeat: no-repeat;
      background-position: 20px center;
      background-size: 25px;
      margin:10px 0;
      text-align:left;
  }
`;


export default ComponentErrorMessage;
