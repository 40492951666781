import React from 'react';
import { connect as redux_connect } from 'react-redux';
import deepEqual from 'deep-eql';

import config from '@/config';

import { currentPage, pagePath } from '@/store/models';
import ErrorBoundary from '@/components/errors/error-boundary';
import Styled from 'styled-components';

export function Page(options) {
    options = Object.assign({}, options);

    return function(Component) {

        const reduxMapStateToProps = function(state) {
            return {
                me: state.me,
            };
        };

        const reduxDispatch = function(dispatch) {
            return {
                setCurrentPage: (pageName) => {
                    dispatch(currentPage.actions.set(pageName));
                },
                setPagePath: (path) => {
                    dispatch(pagePath.actions.set(path));
                },
            };
        };

        class PageWrapper extends React.Component {
            static propTypes = Component.propTypes;
            static displayName = `Page(${Component.displayName || Component.name})`;
            
            getPageName() {
                if (typeof options.name === 'function') {
                    return options.name(this.props);
                }
                else {
                    return options.name || Component.displayName || Component.name;
                }
            }

            getPagePath() {
                if (typeof options.pagePath === 'function') {
                    return options.pagePath(this.props);
                }
                else {
                    return options.pagePath || [];
                }
            }
            
            updatePageInfos() {
                const props = this.props;
                const pageName = this.getPageName();
                const pagePath = this.getPagePath();

                props.setCurrentPage(pageName);
                props.setPagePath(pagePath);

                document.title = pageName ? `${pageName} | ${config.appName}` : config.appName;
            }

            componentDidMount() {
                super.componentDidMount && super.componentDidMount();
                this.updatePageInfos();
            }
            
            componentDidUpdate(prevProps) {
                if (!deepEqual(prevProps, this.props)) {
                    this.updatePageInfos();
                }
            }

            render() {
                const props = this.props;
                return (
                    <ErrorBoundary>
                        <Component {...props}/>
                    </ErrorBoundary>
                );
            }
        }

        //language=SCSS
        PageWrapper = Styled(PageWrapper)`
        & {
            height: 100%;
            //overflow-y: scroll;
        }
        `;
        
        return redux_connect(reduxMapStateToProps, reduxDispatch)(PageWrapper);
    };
}
