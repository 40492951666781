import { model } from '@/store/simpler-redux';

export default model({
    name: 'changelogGiftOpen',
    state: false,
    reducers: {
        toggle: (state) => {
            return !state;
        },
        set: (state, data) => {
            return data;
        },
    },
});
