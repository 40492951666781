import React from 'react';
import Styled from 'styled-components';
import { css, keyframes } from 'styled-components';
import PropTypes from 'prop-types';

const kf = keyframes`
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;
const animation = css`${kf} 1s infinite linear`;

class MiniLoadingView extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
    };

    render() {
        return (
            <i className={`fas fa-circle-notch ${this.props.className}`} />
        );
    }
}

//language=SCSS
MiniLoadingView = Styled(MiniLoadingView)`
& {
    animation: ${animation};
    padding: 0 !important;
}
`;

export { MiniLoadingView };
