import {ChangeEvent} from 'react';
import {useTranslation} from 'react-i18next';

type SelectValue = {
    toString: () => string;
};

type SelectOption = {
    label: string;
    value?: SelectValue | null;
};

interface SelectProps {
    loading?: boolean;
    options: SelectOption[];
    value?: SelectValue | null;
    required?: boolean;
    onChange: (value: SelectOption | undefined) => void;
}

function ControlledSelect({loading, options, value, required, onChange, }: SelectProps) {
    const {t} = useTranslation();
    const handleChange = (e: ChangeEvent<HTMLSelectElement>) => {
        const selectedValue = e.target.value;
        const selectedOption = options.find((option) => `${option.value}` === selectedValue) ?? undefined;
        onChange(selectedOption);
    };

    if (loading) {
        return (
            <select value={value ? value.toString() : ''} disabled>
                <option value="">
                    {t(['Loading', 'global.loading'])}
                </option>
            </select>
        );
    }
    else {
        return (
            <select value={value ? value.toString() : ''} onChange={handleChange} required={required} aria-required={required ? 'true':'false'}>
                {options.map((option, index) => (
                    <option key={index} value={option.value?.toString() ?? undefined}>
                        {option.label}
                    </option>
                ))}
            </select>
        );
    }
}

export {ControlledSelect};
export type {SelectOption};
