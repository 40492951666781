import React from 'react';
import autoBind from 'react-autobind';
import {BackendApiService} from '@/services/backend-api-service';
import PropTypes from 'prop-types';
import ConfirmDeleteForm from '@/components/form/confirm-delete-form';
import {withTranslation} from 'react-i18next';

class PrivacyDeletePersonalDataModal extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        onDeleted: PropTypes.func,
        onCancelled: PropTypes.func,
    };

    state = {
        loading: false,
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={props.className}>
                <ConfirmDeleteForm
                    loading={state.loading}
                    itemName={t(['your account', 'privacyDeletePersonalData.item'])}
                    deleteLabel={t(['Delete my account', 'privacyDeletePersonalData.confirm'])}
                    onCancelled={this.handleCancel}
                    onSubmit={this.handleSubmit}
                />
            </div>
        );
    }

    handleSubmit() {
        this.deletePersonalData();
    }

    handleCancel() {
        if(this.props.onCancelled) {
            this.props.onCancelled();
        }
    }

    async deletePersonalData() {
        try {
            this.setState(state => ({
                ...state,
                loading: true,
            }));

            const personalData = await BackendApiService.getRequest({
                domain: 'privacy',
                modelName: 'privacyDeletePersonalData',
            });

            console.debug('>>>> PERSONAL DATA DELETED', personalData);

            this.setState(state => ({
                ...state,
                loading: false,
            }));
            if(this.props.onDeleted) {
                this.props.onDeleted();
            }
        }
        catch (err) {
            console.error(err);
            this.setState(state => ({
                ...state,
                loading: false,
            }));
        }
    }
}

export default withTranslation()(PrivacyDeletePersonalDataModal);
