import deepEqual from 'deep-eql';
import {Language} from 'gabi-api-js/caregiver/caregiver_common_pb';
import {Gender} from 'gabi-api-js/hcp/hcp_common_pb';
import {CountryCode, isSupportedCountry} from 'libphonenumber-js';
import {E164Number} from 'libphonenumber-js';
import {ChangeEvent, FormEvent, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import PhoneInput, {parsePhoneNumber} from 'react-phone-number-input';
import Styled from 'styled-components';
import { $enum } from 'ts-enum-util';

import {Button} from '@/components/buttons/button';
import {ErrorMessage} from '@/components/form/error-message';
import {GenderEnum} from '@/enum/gender-enum';
import {GeoCountryService} from '@/services/geocontry-service';
import {Caregiver} from '@/types/caregiver';
import {Patient} from '@/types/patient';
import {parseIntToGender} from '@/util/gender-util';
import {parseIntToLanguage} from '@/util/language-util';

import 'react-phone-number-input/style.css';

type PatientCaregiverFormProps = {
    className?: string;
    patient?: Patient;
    caregiver?: Caregiver;
    loading: boolean;
    onChanged?: (hasChanged: boolean) => void;
    onSubmit: (caregiver: Caregiver) => void;
}

function PatientCaregiverFormUnstyled({className, patient, caregiver, loading, onChanged, onSubmit}: PatientCaregiverFormProps) {
    const { t } = useTranslation();
    const [defaultPhoneCountryCode, setDefaultPhoneCountryCode] = useState<CountryCode>('BE');
    const [emailValid, setEmailValid] = useState<boolean>(false);
    const [currentCaregiver, setCurrentCaregiver] = useState<Caregiver>(caregiver ?? {
        id: '',
        firstName: '',
        lastName: '',
        email: '',
        preferredLanguage: Language.LANGUAGE_NOT_SET,
        gender: Gender.GENDER_NOT_SET,
        phoneNumber: {
            number: '',
            countryCode: '',
        },
    });

    useEffect(() => {
        getGeoCountry();
        checkEmailValid(currentCaregiver.email);
    }, []);

    useEffect(() => {
        checkNotSavedDataCaregiver();
    }, [currentCaregiver]);

    const getGeoCountry = async () => {
        try {
            const countryCode = await GeoCountryService.fetchCountryCode();
            if (isSupportedCountry(countryCode)) {
                setDefaultPhoneCountryCode(countryCode);
            }
        }
        catch (error) {
            console.error(error);
        }
    };

    const checkNotSavedDataCaregiver = () => {
        const notSavedDataCaregiver = !deepEqual(currentCaregiver, caregiver);

        if (onChanged) {
            onChanged(notSavedDataCaregiver);
        }
    };

    const checkEmailValid = (email: string) => {
        const pattern = /^\S{1,60}@{1}[a-z0-9_-]{1,60}(\.[a-z0-9_-]{2,60})+$/;
        setEmailValid(pattern.test(email));
    };

    const isFilled = () => {
        return (
            currentCaregiver.firstName && currentCaregiver.firstName !== '' &&
            currentCaregiver.lastName && currentCaregiver.lastName !== '' &&
            currentCaregiver.preferredLanguage !== Language.LANGUAGE_NOT_SET &&
            currentCaregiver.gender !== Gender.GENDER_NOT_SET &&
            currentCaregiver.email && currentCaregiver.email !== '' &&
            currentCaregiver.phoneNumber && (
                currentCaregiver.phoneNumber.countryCode && currentCaregiver.phoneNumber.countryCode !== '' &&
                currentCaregiver.phoneNumber.number && currentCaregiver.phoneNumber.number !== ''
            ) &&
            emailValid
        );
    };

    const handleChangeFirstName = (e: ChangeEvent<HTMLInputElement>) => {
        const newCaregiver: Caregiver = { ...currentCaregiver };
        newCaregiver.firstName = e.target.value;
        setCurrentCaregiver(newCaregiver);
    };

    const handleChangeLastName = (e: ChangeEvent<HTMLInputElement>) => {
        const newCaregiver: Caregiver = { ...currentCaregiver };
        newCaregiver.lastName = e.target.value;
        setCurrentCaregiver(newCaregiver);
    };

    const handleChangePreferredLanguage = (e: ChangeEvent<HTMLSelectElement>) => {
        const newCaregiver: Caregiver = { ...currentCaregiver };
        const value = (e.target.value === '') ? 0 : parseInt(e.target.value);
        newCaregiver.preferredLanguage = parseIntToLanguage(value);
        setCurrentCaregiver(newCaregiver);
    };

    const handleChangeGender = (e: ChangeEvent<HTMLSelectElement>) => {
        const newCaregiver: Caregiver = { ...currentCaregiver };
        const value = (e.target.value === '') ? 0 : parseInt(e.target.value);
        newCaregiver.gender = parseIntToGender(value);
        setCurrentCaregiver(newCaregiver);
    };

    const handleChangeEmail = (e: ChangeEvent<HTMLInputElement>) => {
        const newCaregiver: Caregiver = { ...currentCaregiver };
        newCaregiver.email = e.target.value;
        setCurrentCaregiver(newCaregiver);
    };

    const handleValidateEmail = (e: ChangeEvent<HTMLInputElement>) => {
        const email = e.target.value;
        checkEmailValid(email);
    };

    const handleChangePhoneNumber = (data: E164Number) => {
        if (data) {
            const parsedPhoneNumber = parsePhoneNumber(data);

            if (parsedPhoneNumber) {
                const newCaregiver: Caregiver = { ...currentCaregiver };
                newCaregiver.phoneNumber = {
                    countryCode: parsedPhoneNumber.countryCallingCode,
                    number: parsedPhoneNumber.nationalNumber,
                };
                setCurrentCaregiver(newCaregiver);
            }
        }
        else {
            const newCaregiver: Caregiver = { ...currentCaregiver };
            newCaregiver.phoneNumber = {
                number: '',
                countryCode: '',
            };
            setCurrentCaregiver(newCaregiver);
        }
    };

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (onSubmit) {
            if (onChanged) {
                onChanged(false);
            }
            onSubmit(currentCaregiver);
        }
    };

    return (
        <div className={`patient-caregiver-form ${className ?? ''}`}>
            <form onSubmit={handleSubmit}>
                <p><em>{t(['Fields marked with an asterisk (*) are required.', 'global.mandatoryFields'])}</em></p>
                <div className="multiFieldWrapper">
                    <div className="fieldWrapper">
                        <label htmlFor="patientCaregiver-firstName" className={`mandatoryField ${(currentCaregiver.firstName === '') ? 'empty' : 'filled'}`}>{t(['First name', 'caregiver.firstName'])}</label>
                        <input name="firstName" maxLength={70} id="patientCaregiver-firstName" required aria-required="true" autoFocus value={currentCaregiver.firstName} onChange={handleChangeFirstName} />
                    </div>
                    <div className="fieldWrapper">
                        <label htmlFor="patientCaregiver-lastName" className={`mandatoryField ${(currentCaregiver.lastName === '') ? 'empty' : 'filled'}`}>{t(['Last name', 'caregiver.lastName'])}</label>
                        <input name="lastName" maxLength={70} id="patientCaregiver-lastName" required aria-required="true" value={currentCaregiver.lastName} onChange={handleChangeLastName} />
                    </div>
                </div>
                <div className="multiFieldWrapper">
                    <div className="fieldWrapper">
                        <label htmlFor="patientCaregiver-preferredLanguage" className={`mandatoryField ${(currentCaregiver.preferredLanguage === Language.LANGUAGE_NOT_SET) ? 'empty' : 'filled'}`}>{t(['Preferred language', 'caregiver.preferredLanguage'])}</label>
                        <select name="preferredLanguage" id="patientCaregiver-preferredLanguage" required aria-required="true" value={currentCaregiver.preferredLanguage} onChange={handleChangePreferredLanguage}>
                            <option value="" />
                            {$enum(Language).getEntries().filter(lang => lang[1] !== 0).map((language) => (
                                <option key={language[0]} value={language[1]}>{t('global.language.' + language[0])}</option>
                            ))}
                        </select>
                    </div>
                    <div className="fieldWrapper">
                        <label htmlFor="patientCaregiver-gender" className={`mandatoryField ${(currentCaregiver.gender === Gender.GENDER_NOT_SET) ? 'empty' : 'filled'}`}>{t(['Pronoun', 'caregiver.gender'])}</label>
                        <select name="gender" id="patientCaregiver-gender" required aria-required="true" value={currentCaregiver.gender} onChange={handleChangeGender}>
                            <option value="" />
                            {Object.entries(GenderEnum).map((gender) => (
                                <option key={gender[1]} value={gender[1]}>{t('global.gender.' + gender[0])}</option>
                            ))}
                        </select>
                    </div>
                </div>
                <div className="multiFieldWrapper">
                    <div className="fieldWrapper">
                        <label htmlFor="patientCaregiver-email" className={`mandatoryField ${(currentCaregiver.email === '') ? 'empty' : 'filled'}`}>{t(['Email', 'caregiver.email'])}</label>
                        <input name="email" maxLength={320} id="patientCaregiver-email" required aria-required="true" value={currentCaregiver.email} onBlur={handleValidateEmail} onChange={handleChangeEmail} />

                        {currentCaregiver.email && !emailValid &&
                            <div className="errorWrapper">
                                <ErrorMessage text={t(['Invalid email', 'caregiver.emailError'])} errorMessage={t(['Please enter a valid email address', 'caregiver.emailErrorMessage'])} />
                            </div>
                        }
                    </div>
                    <div className="fieldWrapper">
                        <label htmlFor="patientCaregiver-phone-number" className={`mandatoryField ${(!currentCaregiver.phoneNumber || currentCaregiver.phoneNumber.number === '') ? 'empty' : 'filled'}`}>{t(['Phone number', 'caregiver.phoneNumber'])}</label>
                        <PhoneInput
                            value={((currentCaregiver.phoneNumber) ? `+${currentCaregiver.phoneNumber.countryCode}${currentCaregiver.phoneNumber.number}` : '') as E164Number}
                            defaultCountry={defaultPhoneCountryCode}
                            onChange={handleChangePhoneNumber}
                        />
                    </div>
                </div>
                <div className="step-form-actions">
                    <Button className="button-create" disabled={!isFilled()} loading={loading}>{(patient) ? t(['Save', 'caregiver.save']) : t(['Next', 'caregiver.next'])}</Button>
                </div>
            </form>
        </div>
    );
}

//language=SCSS
const PatientCaregiverForm = Styled(PatientCaregiverFormUnstyled)`
& {
    .PhoneInputCountryIcon--border {
        box-shadow: inherit;
        background: none;
    }
    
    .PhoneInputCountryIcon {
        width:60px;
        height:38px;
    }
}
`;

export {PatientCaregiverForm};
