import { model } from '@/store/simpler-redux';

/**
 * Contains the GET routes for each defined model. The keys of this object must match a model defined in @/store/models
 */
export const fetchMap = {
    syncUsersStatus: { service: ':client', path: '/sync-users-status' },
    
    customer: { service: ':client', path: '/customer' },

    users: { service: ':client', path: '/users' },
    patients: { service: ':client', path: '/patients' },
    patientEvents: { service: ':client', path: '/patient-notes-alerts' },
    patientMetricDistribution: { service: ':client', path: '/patient-notes-metric-distribution' },

    settings: { service: ':client', path: '/settings' },
};
//----------------------------------------------------------------------------------------------------------------------
/*
const models = arrayToMap(Object.keys(fetchMap).map(modelName => asyncModel({
    name: `api-${modelName}`,
    state: null,
    reducers: {},
    actions: {},
})), m => m.name, m => m);*/

const models = {
    'backend-api': model({
        name: 'backend-api',
        state: {},
        reducers: {
            clear: () => {
                return {};
            },
    
            prepare: (state, data) => {
                data = Object.assign({}, data);
        
                const newState = { ...state };
                newState[data.request] = data;
                return newState;
            },
    
            update: (state, data) => {
                data = Object.assign({}, data);
        
                const newState = { ...state };
                newState[data.request] = Object.assign({}, newState[data.request], data);
                return newState;
            },
    
            remove: (state, data) => {
                data = Object.assign({}, data);
        
                const newState = { ...state };
                delete newState[data.request];
                return newState;
            },
        },
    })
};

export { models };
