import config from '@/config';
import {AlertTypeEnum} from '@/enum/alert-type-enum';
import {ErrorTrackingService} from '@/services/error-tracking-service';
import {FeedbackAlertsService} from '@/services/feedback-alerts-service';

const location = document.location;
//const port = (location.port !== 80 && location.port !== 443) ? `:${location.port}` : '';
//const baseUrl = (location.hostname === 'localhost' && __GABI_ENV__ === 'development') ? 'https://pediarity-api-m3ol5225qa-ew.a.run.app' : `${location.protocol}//${location.hostname}${port}`;
const domainEnvPrefix = (
    (location.hostname === 'localhost' && __GABI_ENV__ === 'development') ? 'dev.' :
        (__GABI_ENV__ === 'production') ? '' :
            `${location.hostname.split('.')[0]}.`
);

const baseUrl = (
    `https://${domainEnvPrefix}api.gabismartcare.com`
);

/** @type {import("grpc-web").UnaryInterceptor<unknown, unknown>[]} */
const unaryInterceptors = window.__gRPC_devtools__ ? [window.__gRPC_devtools__.gRPCDevtoolsUnaryInterceptor] : [];

/** @type {import("grpc-web").StreamInterceptor<unknown, unknown>[]} */
const streamInterceptors = window.__gRPC_devtools__ ? [window.__gRPC_devtools__.gRPCDevtoolsStreamInterceptor] : [];

const API_KEY = config.firebase.apiKey;

const getApiUrl = () => {
    return `${baseUrl}`;
};

const getGrpcMetadata = (idToken) => {
    const metadata = {
        'x-api-key': API_KEY,
        'caller-id': 'analytics-web',
        'caller-version': `${__APP_VERSION__}-${__COMMIT_HASH__}`
    };

    // In case of request without token
    if(idToken !== '') {
        metadata['Authorization'] = `Bearer ${idToken}`;
    }

    return metadata;
};

const grpcRequest = (idToken, ServiceClient, fn, req) => {
    return new Promise((resolve, reject) => {

        if(__GABI_ENV__ !== 'production') {
            console.debug('REQUEST PARAMS - ' + fn, req.toObject());
        }

        const service = new ServiceClient(getApiUrl(idToken), undefined, {
            unaryInterceptors: unaryInterceptors,
            streamInterceptors: streamInterceptors,
        });
        service[fn](req, getGrpcMetadata(idToken),
            (err, response) => {
                if (err) {
                    const backendErrorCode = err.message?.substring(0, 7); // Get error code from backend error message
                    const backendErrorMessage = err.message?.substring(8, err.message.length); // Get error code from backend error message
                    FeedbackAlertsService.add(AlertTypeEnum.ERROR, fn, backendErrorMessage, `${backendErrorCode}`);
                    ErrorTrackingService.captureApiException(err, fn);
                    reject(err);
                }
                else {
                    if(__GABI_ENV__ !== 'production') {
                        console.debug('RESPONSE - ' + fn, response.toObject());
                    }
                    resolve(response.toObject());
                }
            });
    });
};

export default grpcRequest;
