import { model } from '@/store/simpler-redux';

export default model({
    name: 'currentPatient',
    state: null,
    reducers: {
        set: (state, data) => {
            return data;
        },
    },
});
