export const PatientChildSkinTypeLabel = {
    1: 'Fitzpatrick I',
    2: 'Fitzpatrick II',
    3: 'Fitzpatrick III',
    4: 'Fitzpatrick IV',
    5: 'Fitzpatrick V',
    6: 'Fitzpatrick VI',
};


export const PatientChildSkinTypeEnum = {
    FITZPATRICK_I: 1,
    FITZPATRICK_II: 2,
    FITZPATRICK_III: 3,
    FITZPATRICK_IV: 4,
    FITZPATRICK_V: 5,
    FITZPATRICK_VI: 6,
};
