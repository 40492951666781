import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import {colorPalette} from '@/themes/darkmode.js';
import warningIcon from '@/public/icons/warning-icon.svg';

class WarningMessage extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        warningMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
        text: PropTypes.string,
    };

    render() {
        const props = this.props;

        return (
            <div className={`warning-message ${props.className}`}>
                {props.warningMessage}<br />
                {props.text}
            </div>
        );
    }
}

//language=SCSS
WarningMessage = Styled(WarningMessage)`
& {
    padding: 10px 10px 10px 60px;
    border-radius: 5px;
    background-color:${colorPalette.warning};
    background-image: url(${warningIcon});
    background-repeat: no-repeat;
    background-position: 20px center;
    background-size: 25px;
    margin:10px 0;
    text-align:left;
}
`;

export { WarningMessage };
