import {PhoneNumber} from 'gabi-api-js/hcp/hcp_common_pb';
import {Date as BirthDate} from 'gabi-api-js/patient/patient_common_pb';
import {PrivacyCommandServiceClient} from 'gabi-api-js/privacy/privacy_command_grpc_web_pb';
import {UpdatePersonalDataRequest} from 'gabi-api-js/privacy/privacy_command_pb';
import {CaregiverData, PatientData} from 'gabi-api-js/privacy/privacy_common_pb';
import {PrivacyQueryServiceClient} from 'gabi-api-js/privacy/privacy_query_grpc_web_pb';
import { Empty } from 'google-protobuf/google/protobuf/empty_pb';

import grpcRequest from '@/services/api-requests/grpc-request';
import {
    buildGender,
    buildPatientSex,
    buildPatientSkinType,
} from '@/services/api-requests/requests-utils';

const serviceQueryClient = PrivacyQueryServiceClient;
const serviceCommandClient = PrivacyCommandServiceClient;

export class PrivacyService {
    async getRequest(idToken, options) {
        options = Object.assign({
            modelName: null,
            method: null, // 'create' | 'readOne' | 'readAll' | 'update' | 'delete'
            id: null,
            filter: null, // Mutually exlusive with id
            payload: null,
        }, options);

        switch(options.modelName) {
        case 'privacyPersonalData': {
            return this.fetchPrivacyPersonalData(idToken, options.id);
        }

        case 'privacyIsTokenValid': {
            return this.fetchPrivacyIsTokenValid(idToken, options.id);
        }

        case 'privacyDeletePersonalData': {
            return this.privacyDeletePersonalData(idToken, options.id);
        }

        case 'privacyUpdatePersonalDataChild': {
            return this.privacyUpdatePersonalDataChild(idToken, options.data);
        }

        case 'privacyUpdatePersonalDataCaregiver': {
            return this.privacyUpdatePersonalDataCaregiver(idToken, options.data);
        }
        
        default: {
            console.error('UNKNOWN REQUEST', options.modelName);
        }
        }
    }

    fetchPrivacyPersonalData(idToken) {
        const req = new Empty();
        return (
            grpcRequest(idToken, serviceQueryClient, 'getPersonalData', req)
                .then(personalData => {
                    return personalData;
                })
        );
    }

    fetchPrivacyIsTokenValid(idToken) {
        const req = new Empty();
        return (
            grpcRequest(idToken, serviceQueryClient, 'isTokenValid', req)
                .then(response => {
                    return response.tokenIsValid;
                })
        );
    }

    privacyDeletePersonalData(idToken) {
        const req = new Empty();
        return (
            grpcRequest(idToken, serviceCommandClient, 'deletePersonalData', req)
                .then(response => {
                    return response;
                })
        );
    }

    privacyUpdatePersonalDataChild(idToken, data) {
        const req = new UpdatePersonalDataRequest();

        req.setPatientData(
            new PatientData()
                .setFirstName(data.patient.firstName)
                .setLastName(data.patient.lastName)
                .setBirthDate(
                    new BirthDate()
                        .setDay(data.patient.birthDate.day)
                        .setMonth(data.patient.birthDate.month)
                        .setYear(data.patient.birthDate.year)
                )
                .setSex(buildPatientSex(data.patient.sex))
                .setSkinType(buildPatientSkinType(data.patient.skinType))
        );

        return (
            grpcRequest(idToken, serviceCommandClient, 'updatePersonalData', req)
                .then(result => {
                    return result;
                })
        );
    }

    privacyUpdatePersonalDataCaregiver(idToken, data) {
        const req = new UpdatePersonalDataRequest();

        req.setCaregiverData(
            new CaregiverData()
                .setFirstName(data.caregiver.firstName)
                .setLastName(data.caregiver.lastName)
                .setEmail(data.caregiver.email)
                .setPreferredLanguage(data.caregiver.preferredLanguage)
                .setGender(buildGender(data.caregiver.gender))

                .setPhoneNumber(
                    new PhoneNumber()
                        .setNumber(data.caregiver.phoneNumber.number)
                        .setCountryCode(data.caregiver.phoneNumber.countryCode)
                )
        );

        return (
            grpcRequest(idToken, serviceCommandClient, 'updatePersonalData', req)
                .then(result => {
                    return result;
                })
        );
    }
}
