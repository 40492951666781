import {Language} from 'gabi-api-js/caregiver/caregiver_common_pb';
import {$enum} from 'ts-enum-util';

export function getI18nLanguageKey(language: Language | '') {
    if (language === '') {
        language = Language.LANGUAGE_NOT_SET;
    }
    switch (language) {
    case Language.LANGUAGE_NOT_SET:
    case Language.ENGLISH:
        return 'en';
    case Language.FRENCH:
        return 'fr';
    case Language.SPANISH:
        return 'es';
    }
}

export function parseIntToLanguage(value: number): Language {
    for (const language of $enum(Language).getEntries()) {
        if (language[1] === value) {
            return Language[language[0]];
        }
    }
    return Language.LANGUAGE_NOT_SET;
}
