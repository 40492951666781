import {CaregiverId} from 'gabi-api-js/caregiver/caregiver_common_pb';
import {PatientCommandServiceClient} from 'gabi-api-js/patient/patient_command_grpc_web_pb';
import {
    CaregiverAdditionToPatientRequest,
    Patient, PatientCorrectionRequest, PrescriberAssignationRequest,
    UpdateEventConfigurationRequest, UpdateHealthConditionDetailsRequest, UpdateHealthConditionRequest,
} from 'gabi-api-js/patient/patient_command_pb';
import {Date as BirthDate, PrescriberId} from 'gabi-api-js/patient/patient_common_pb';
import {PatientQueryServiceClient} from 'gabi-api-js/patient/patient_query_grpc_web_pb';
import {InactivePatientsQuery, PeriodQuery} from 'gabi-api-js/patient/patient_query_pb';
import { PatientId } from 'gabi-api-js/signal/signal_common_pb';
import { Empty } from 'google-protobuf/google/protobuf/empty_pb';

import {DemoPatient} from '@/components/business/analytics/patient/common/demo-patient';
import grpcRequest from '@/services/api-requests/grpc-request';
import {
    buildDate,
    buildEventConfiguration, buildPatientSex, buildPatientSkinType,
    formatJSDateToApiDate,
} from '@/services/api-requests/requests-utils';

const serviceQueryClient = PatientQueryServiceClient;
const serviceCommandClient = PatientCommandServiceClient;

export class PatientService {
    async getRequest(idToken, options) {
        options = Object.assign({
            modelName: null,
            method: null, // 'create' | 'readOne' | 'readAll' | 'update' | 'delete'
            id: null,
            filter: null, // Mutually exlusive with id
            payload: null,
        }, options);

        switch(options.modelName) {
        case 'getEventConfiguration' : {
            return this.getEventConfiguration(idToken, options.id);
        }
        case 'updateEventConfiguration' : {
            return this.updateEventConfiguration(idToken, options.data);
        }
        case 'getActivePatients' : {
            return this.getActivePatients(idToken, options.data);
        }
        case 'getInactivePatients' : {
            return this.getInactivePatients(idToken, options.data);
        }
        case 'getPatients' : {
            return this.getPatients(idToken);
        }
        case 'getPatientDetails': {
            return this.getPatientDetails(idToken, options.data);
        }
        case 'getPatientCaregiver': {
            return this.getPatientCaregiver(idToken, options.data);
        }
        case 'getHealthCondition': {
            return this.getHealthCondition(idToken, options.data);
        }
        case 'listHealthConditionTypes': {
            return this.listHealthConditionTypes(idToken, options.data);
        }
        case 'addHealthCondition': {
            return this.addHealthCondition(idToken, options.data);
        }
        case 'updateHealthConditionDetails': {
            return this.updateHealthConditionDetails(idToken, options.data);
        }
        case 'removeHealthCondition': {
            return this.removeHealthCondition(idToken, options.data);
        }
        case 'createPatient': {
            return this.createPatient(idToken, options.data);
        }
        case 'updatePatient': {
            return this.updatePatient(idToken, options.data);
        }
        case 'deletePatient': {
            return this.deletePatient(idToken, options.data);
        }
        case 'assignCaregiverToPatient': {
            return this.assignCaregiverToPatient(idToken, options.data);
        }
        case 'assignPrescriberToPatient': {
            return this.assignPrescriberToPatient(idToken, options.data);
        }
        case 'getPatientPrescriber': {
            return this.fetchPatientPrescriber(idToken, options.data);
        }
        case 'generatePatientQrCode': {
            return this.generatePatientQrCode(idToken, options.data);
        }
        case 'getLatestQrCode': {
            return this.getLatestQrCode(idToken, options.data);
        }

        default: {
            console.error('UNKNOWN REQUEST', options.modelName);
        }
        }
    }

    getEventConfiguration(idToken, id) {
        if(id === 'demo') {
            return DemoPatient.patient.eventConfiguration;
        } else {
            const req = new PatientId();
            req.setId(id);
            return (
                grpcRequest(idToken, serviceQueryClient, 'getEventConfiguration', req)
                    .then(result => {
                        return result;
                    })
            );
        }
    }

    updateEventConfiguration(idToken, data) {
        const req = new UpdateEventConfigurationRequest();
        req.setPatientId(new PatientId().setId(data.patientId));
        req.setEventConfiguration(buildEventConfiguration(data.eventConfiguration));
        return (
            grpcRequest(idToken, serviceCommandClient, 'updateEventConfiguration', req)
                .then(result => {
                    return result;
                })
        );
    }

    getActivePatients(idToken, data) {
        const req = new PeriodQuery();
        req.setFrom(buildDate(formatJSDateToApiDate(data.dateFrom)));
        req.setTo(buildDate(formatJSDateToApiDate(data.dateTo)));
        return (
            grpcRequest(idToken, serviceQueryClient, 'getPatientsWithRecentRecording', req)
                .then(allResults => {
                    // ---- DEBUG CODE FOR DEVELOPMENT ----
                    // const johnDoe = allResults.patientsList
                    //     .find(p => p.patient.patientId.id === '7cc9e27a-5e06-42b8-9899-0afe092cec76' /* John Doe */);
                    //
                    // console.log('JOHN DOE');
                    // console.log(johnDoe);
                    //
                    // johnDoe.lastRecordingInformation.pulseRate.statistics.hasData = 0;
                    // johnDoe.lastRecordingInformation.pulseRate.eventsCountLow.hasData = 0;
                    // johnDoe.lastRecordingInformation.pulseRate.eventsCountHigh.hasData = 0;
                    // johnDoe.lastRecordingInformation.spo2.statistics.hasData = 0;
                    // johnDoe.lastRecordingInformation.spo2.eventPercent.hasData = 0;
                    // ---- DEBUG CODE FOR DEVELOPMENT ----
                    
                    return allResults;
                })
        );
    }

    getInactivePatients(idToken, data) {
        const req = new InactivePatientsQuery();

        req.setNoRecordingSince(buildDate(formatJSDateToApiDate(data.noRecordingSince)));
        req.setHasRecordingAfter(buildDate(formatJSDateToApiDate(data.hasRecordingAfter)));
        req.setCreatedAfter(buildDate(formatJSDateToApiDate(data.createdAfter)));
        return (
            grpcRequest(idToken, serviceQueryClient, 'getInactivePatients', req)
                .then(allResults => {
                    return allResults;
                })
        );
    }

    getPatients(idToken) {
        const req = new Empty;
        return (
            grpcRequest(idToken, serviceQueryClient, 'getPatients', req)
                .then(allResults => {
                    return allResults;
                })
        );
    }

    getPatientDetails(idToken, data) {
        if(data.patientId === 'demo') {
            return DemoPatient.patient;
        } else {
            const req = new PatientId();
            req.setId(data.patientId);
            return (
                grpcRequest(idToken, serviceQueryClient, 'get', req)
                    .then(allResults => {
                        return allResults;
                    })
            );
        }
    }

    getPatientCaregiver(idToken, data) {
        const req = new PatientId();
        req.setId(data.patientId);
        return (
            grpcRequest(idToken, serviceQueryClient, 'getCaregiver', req)
                .then(allResults => {
                    return allResults;
                })
        );
    }

    getHealthCondition(idToken, data) {
        const req = new PatientId();
        req.setId(data.patientId);
        return (
            grpcRequest(idToken, serviceQueryClient, 'getHealthCondition', req)
                .then(result => {
                    return result;
                })
        );
    }

    listHealthConditionTypes(idToken) {
        const req = new Empty();
        return (
            grpcRequest(idToken, serviceQueryClient, 'listHealthConditionTypes', req)
                .then(result => {
                    return result;
                })
        );
    }

    addHealthCondition(idToken, data) {
        const req = new UpdateHealthConditionRequest();
        req.setPatientId(new PatientId().setId(data.patientId));
        req.setHealthCondition(data.healthCondition);
        return (
            grpcRequest(idToken, serviceCommandClient, 'addHealthCondition', req)
                .then(result => {
                    return result;
                })
        );
    }

    updateHealthConditionDetails(idToken, data) {
        const req = new UpdateHealthConditionDetailsRequest();
        req.setPatientId(new PatientId().setId(data.patientId));
        req.setHealthConditionDetail(data.healthConditionDetails);
        return (
            grpcRequest(idToken, serviceCommandClient, 'updateHealthConditionDetails', req)
                .then(result => {
                    return result;
                })
        );
    }

    removeHealthCondition(idToken, data) {
        const req = new UpdateHealthConditionRequest();
        req.setPatientId(new PatientId().setId(data.patientId));
        req.setHealthCondition(data.healthCondition);
        return (
            grpcRequest(idToken, serviceCommandClient, 'removeHealthCondition', req)
                .then(result => {
                    return result;
                })
        );
    }

    createPatient(idToken, data) {
        const req = new Patient();

        req.setFirstName(data.patient.firstName);
        req.setLastName(data.patient.lastName);

        req.setBirthDate(new BirthDate()
            .setDay(data.patient.birthDate.day)
            .setMonth(data.patient.birthDate.month)
            .setYear(data.patient.birthDate.year)
        );

        req.setSex(buildPatientSex(data.patient.sex));
        req.setSkinType(buildPatientSkinType(data.patient.skinType));

        req.setNoMonitoringRequired(data.patient.noMonitoringRequired);

        return (
            grpcRequest(idToken, serviceCommandClient, 'createPatient', req)
                .then(result => {
                    return result;
                })
        );
    }

    updatePatient(idToken, data) {
        const req = new PatientCorrectionRequest();

        req.setPatientId(new PatientId().setId(data.patient.id));
        req.setFirstName(data.patient.firstName);
        req.setLastName(data.patient.lastName);

        req.setBirthDate(new BirthDate()
            .setDay(data.patient.birthDate.day)
            .setMonth(data.patient.birthDate.month)
            .setYear(data.patient.birthDate.year)
        );

        req.setSex(buildPatientSex(data.patient.sex));
        req.setSkinType(buildPatientSkinType(data.patient.skinType));

        return (
            grpcRequest(idToken, serviceCommandClient, 'correctPatientData', req)
                .then(result => {
                    return result;
                })
        );
    }

    deletePatient(idToken, data) {
        const req = new PatientId();
        req.setId(data.patientId);

        return (
            grpcRequest(idToken, serviceCommandClient, 'delete', req)
                .then(result => {
                    return result;
                })
        );
    }

    assignCaregiverToPatient(idToken, data) {
        const req = new CaregiverAdditionToPatientRequest();

        req.setPatientId(new PatientId().setId(data.patientId));
        req.setCaregiverId(new CaregiverId().setId(data.caregiverId));

        return (
            grpcRequest(idToken, serviceCommandClient, 'addCaregiverToPatient', req)
                .then(result => {
                    return result;
                })
        );
    }

    assignPrescriberToPatient(idToken, data) {
        const req = new PrescriberAssignationRequest();

        req.setPatientId(new PatientId().setId(data.patientId));
        req.setPrescriberId(new PrescriberId().setId(data.prescriberId));

        return (
            grpcRequest(idToken, serviceCommandClient, 'assignPrescriber', req)
                .then(result => {
                    return result;
                })
        );
    }

    fetchPatientPrescriber(idToken, data) {
        const req = new PatientId();
        req.setId(data.patientId);
        return (
            grpcRequest(idToken, serviceQueryClient, 'getPrescriber', req)
                .then(allResults => {
                    return allResults;
                })
        );
    }

    generatePatientQrCode(idToken, data) {
        const req = new PatientId();
        req.setId(data.id);
        return (
            grpcRequest(idToken, serviceCommandClient, 'generateQrCode', req)
                .then(allResults => {
                    return allResults;
                })
        );
    }

    getLatestQrCode(idToken, data) {
        const req = new PatientId();
        req.setId(data.id);
        return (
            grpcRequest(idToken, serviceQueryClient, 'getLatestQrCode', req)
                .then(allResults => {
                    return allResults;
                })
        );
    }
}
