import React from 'react';
import PropTypes from 'prop-types';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';

import {Button} from '@/components/buttons/button';
import {Modal} from '@/components/layout/modal';
import PrivacyDeletePersonalDataModal from '@/components/business/privacy/privacy-delete-personal-data-modal';
import {InfoMessage} from '@/components/form/info-message';

class PrivacyDeletePersonalData extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        onDeleted: PropTypes.func,
    };

    state = {
        showDeleteModal: false,
        deleteText: '',
        reason: '',
    };

    constructor(props) {
        super(props);
        autoBind(this);
    }

    isFilled() {
        const state = this.state;
        return (
            state.deleteText && state.deleteText === 'DELETE'
        );
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={props.className}>
                {state.showDeleteModal &&
                    <Modal title={t(['Delete my account', 'privacyDeletePersonalData.title'])} onClosed={this.handleCloseModal}>
                        <PrivacyDeletePersonalDataModal onDeleted={this.handlePersonalDataDeleted} onCancelled={this.handleCloseModal} />
                    </Modal>
                }

                <h3>{t(['Delete my account', 'privacyDeletePersonalData.title'])}</h3>
                <InfoMessage
                    infoMessage={t(['Be careful, this action is irreversible.', 'privacyDeletePersonalData.info'])}
                    text={t(['All your personal data will be deleted.', 'privacyDeletePersonalData.infoText'])}
                />

                <form onSubmit={this.handleOpenDeleteModal}>
                    <div className="fieldWrapper">
                        <label htmlFor="deleteAccount-deleteText" className={`mandatoryField ${(state.deleteText !== 'DELETE') ? 'empty' : 'filled'}`}>
                            {t(['Please write', 'privacyDeletePersonalData.pleaseWrite'])} <strong>DELETE</strong> {t(['in the text field.', 'privacyDeletePersonalData.inTextField'])}
                        </label>
                        <input name="text" id="deleteAccount-deleteText" required aria-required="true" autoFocus value={state.firstName} onChange={this.handleChangeDeleteText} />
                    </div>
                    {this.isFilled() &&
                        <div className="fieldWrapper">
                            <label htmlFor="deleteAccount-reasonText">{t(['What is the reason of your offboarding?', 'privacyDeletePersonalData.reason'])}</label>
                            <input name="text" id="deleteAccount-reasonText" value={state.reason} onChange={this.handleChangeReason}/>
                        </div>
                    }
                    <div className="step-form-actions">
                        <Button className="button-delete" displayIcon={true} disabled={!this.isFilled()}>{t(['Delete my account', 'privacyDeletePersonalData.confirm'])}</Button>
                    </div>
                </form>
            </div>
        );
    }

    handleChangeDeleteText(e) {
        this.setState(state => ({
            ...state,
            deleteText: e.target.value,
        }));
    }

    handleChangeReason(e) {
        this.setState(state => ({
            ...state,
            reason: e.target.value,
        }));
    }

    handleOpenDeleteModal(e) {
        e.preventDefault();
        this.setState(state => ({
            ...state,
            showDeleteModal: true,
        }));
    }

    handleCloseModal() {
        this.setState(state => ({
            ...state,
            showDeleteModal: false,
        }));
    }

    handlePersonalDataDeleted() {
        if(this.props.onDeleted) {
            this.props.onDeleted();
        }
    }
}

PrivacyDeletePersonalData = withTranslation()(PrivacyDeletePersonalData);
export {PrivacyDeletePersonalData};
