class GeoCountryService_ {
    async fetchCountryCode(): Promise<string> {
        try {
            const response = await fetch('https://ipapi.co/json/');
            if (!response.ok) {
                throw new Error('ipapi.co: Network response was not ok');
            }
            const data = await response.json();
            return data.country_code;
        }
        catch (e) {
            const error = new Error(`ipapi.co: There was a problem with the fetch operation: ${(e as Error).message}`);
            error.stack = (e as Error).stack;
            throw error;
        }
    }
}

const GeoCountryService = new GeoCountryService_();

export { GeoCountryService };
