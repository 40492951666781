import React from 'react';
import Styled from 'styled-components';
import { Page } from '@/decorators/page';
import PropTypes from 'prop-types';
import {withTranslation} from 'react-i18next';

function wrapWithPage(Component) {
    return Page({
        name: 'Page not found'
    })(Component);
}

class Err404Page extends React.Component {

    static propTypes = {
        t: PropTypes.func,
        className: PropTypes.string,
    };

    render() {
        const { t }= this.props;

        return (
            <div className={this.props.className}>
                <h1 className="text-center">{t(['Page not found', 'pages.error404.title'])}</h1>
                <h2 className="text-center">{t(['Error 404', 'pages.error404.error'])}</h2>
            </div>
        );
    }

    shouldComponentUpdate() {
        return false;
    }
}

//language=SCSS
Err404Page = Styled(Err404Page)`
& {
  padding: 100px 0 0 0;
}
`;

wrapWithPage(Err404Page);

export default withTranslation()(Err404Page);
