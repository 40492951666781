import {subYears, subDays} from 'date-fns';
import deepEqual from 'deep-eql';
import PropTypes from 'prop-types';
import React from 'react';
import autoBind from 'react-autobind';
import {withTranslation} from 'react-i18next';
import Styled from 'styled-components';

import PatientSkinTypeTooltip from '@/components/business/analytics/patient/common/patient-skin-type-tooltip';
import {Button} from '@/components/buttons/button';
import {DateInput} from '@/components/form/input/date-input';
import {PatientChildSexEnum} from '@/enum/patient-child-sex-enum';
import {
    PatientChildSkinTypeEnum,
    PatientChildSkinTypeLabel,
} from '@/enum/patient-child-skin-type-enum';
import {NewDateUtil} from '@/util/new-date-util';

import '@/stylesheets/datepicker-darkmode-theme.css';

class PatientChildForm extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        patient: PropTypes.object,
        hideCheckboxes: PropTypes.bool,
        loading: PropTypes.bool.isRequired,
        onChanged: PropTypes.func.isRequired,
        onSubmit: PropTypes.func.isRequired
    };

    state = {
        firstName: '',
        lastName: '',
        isBirthDateValid: false,
        birthDate: {
            day: undefined,
            month: undefined,
            year: undefined,
        },
        sex: '',
        skinType: '',
        noMonitoringRequired: false,
        oldChild: false,
        neoChild: false,
        displayOldChild: false,
        displayNeoChild: false,
    };

    constructor(props) {
        super(props);

        autoBind(this);

        if(props.patient) {
            const today = NewDateUtil();
            const birthDate = new Date(props.patient.birthDate.year, props.patient.birthDate.month - 1, props.patient.birthDate.day);
            const oldChildDate = subYears(today, 13);
            const neoChildDate = subDays(today, 28);

            birthDate.setHours(12, 0, 0, 0);
            oldChildDate.setHours(12, 0, 0, 0);
            neoChildDate.setHours(12, 0, 0, 0);

            this.state = {
                firstName: props.patient.firstName,
                lastName: props.patient.lastName,
                isBirthDateValid: true,
                birthDate: {
                    day: props.patient.birthDate.day,
                    month: props.patient.birthDate.month,
                    year: props.patient.birthDate.year,
                },
                sex: props.patient.sex,
                skinType: props.patient.skinType,
                noMonitoringRequired: true,
                oldChild: birthDate <= oldChildDate,
                neoChild: birthDate > neoChildDate,
                displayOldChild: birthDate <= oldChildDate,
                displayNeoChild: birthDate > neoChildDate,
            };
        }

        this.initialState = this.state;
    }

    isFilled() {
        const state = this.state;

        return (
            state.firstName && state.firstName !== '' &&
            state.lastName && state.lastName !== '' &&
            state.birthDate && state.birthDate.day !== '' && state.birthDate.month !== '' && state.birthDate.year !== '' &&
            state.isBirthDateValid &&
            state.sex && state.sex !== '' &&
            state.skinType && state.skinType !== '' &&
            state.noMonitoringRequired &&
            (!state.displayOldChild || state.displayOldChild && state.oldChild) &&
            (!state.displayNeoChild || state.displayNeoChild && state.neoChild)
        );
    }

    checkNotSavedDataChild() {
        if(this.props.onChanged) {
            const notSavedDataChild = !deepEqual(this.state, this.initialState);
            this.props.onChanged(notSavedDataChild);
        }
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;

        return (
            <div className={props.className}>
                <form onSubmit={this.handleSubmit}>
                    <p><em>{t(['Fields marked with an asterisk (*) are required.', 'global.mandatoryFields'])}</em></p>
                    <div className="multiFieldWrapper">
                        <div className="fieldWrapper">
                            <label htmlFor="patientChild-firstName" className={`mandatoryField ${(state.firstName === '') ? 'empty' : 'filled'}`}>{t(['First name', 'patient.firstName'])}</label>
                            <input name="firstName" maxLength={70} id="patientChild-firstName" required aria-required="true" autoFocus value={state.firstName} onChange={this.handleChangeFirstName} />
                        </div>
                        <div className="fieldWrapper">
                            <label htmlFor="patientChild-lastName" className={`mandatoryField ${(state.lastName === '') ? 'empty' : 'filled'}`}>{t(['Last name', 'patient.lastName'])}</label>
                            <input name="lastName" maxLength={70} id="patientChild-lastName" required aria-required="true" value={state.lastName} onChange={this.handleChangeLastName} />
                        </div>
                    </div>
                    <div className="multiFieldWrapper">
                        <div className="fieldWrapper">
                            <label htmlFor="patientChild-sex" className={`mandatoryField ${(state.sex === '') ? 'empty' : 'filled'}`}>{t(['Sex', 'patient.sex'])}</label>
                            <select name="sex" id="patientChild-sex" required aria-required="true" value={state.sex} onChange={this.handleChangeSex}>
                                <option value="" />
                                {Object.entries(PatientChildSexEnum).map((sex) => (
                                    <option key={sex[0]} value={sex[1]}>{t('patient.sexLabel.' + sex[0])}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                    <div className="fieldWrapper">
                        <label htmlFor="patientChild-skinType" className={`mandatoryField ${(state.skinType === '') ? 'empty' : 'filled'}`}>{t(['Skin color', 'patient.skinColor'])}</label>
                        <select name="skinType" id="patientChild-skinType" className="hidden" required aria-required="true" disabled>
                            <option value="" />
                            {Object.entries(PatientChildSkinTypeEnum).map((skinType) => (
                                <option key={skinType[0]} value={skinType[1]}>{PatientChildSkinTypeLabel[skinType[1]]}</option>
                            ))}
                        </select>
                        <div className="patientChild-skinType-selection">
                            {Object.entries(PatientChildSkinTypeEnum).map((skinType) => (
                                <PatientSkinTypeTooltip
                                    key={`patient-skin-type-button-${skinType[1]}`}
                                    skinType={skinType[1]}
                                    selected={state.skinType === skinType[1]}
                                    onSelected={() => this.handleChangeSkinColor(skinType[1])}
                                />
                            ))}
                        </div>
                    </div>
                    <div className="fieldWrapper">
                        <label htmlFor="patientChild-birthDate-day" className={`mandatoryField ${(!state.isBirthDateValid) ? 'empty' : 'filled'}`}>{t(['Birth date', 'patient.birthDate'])}</label>
                        <div className="fieldWrapper">
                            <DateInput value={state.birthDate} onChange={this.handleChangeBirthDate} minYear={1950} maxYear={new Date().getFullYear()} />
                        </div>
                    </div>
                    {!props.hideCheckboxes &&
                        <div className="fieldWrapper">
                            <div className="checkboxFieldWrapper">
                                <input
                                    type="checkbox"
                                    name="noMonitoringRequired"
                                    id="patientChild-noMonitoringRequired"
                                    required
                                    aria-required="true"
                                    value={this.state.noMonitoringRequired}
                                    checked={this.state.noMonitoringRequired}
                                    disabled={!!(this.props.patient)}
                                    onChange={this.handleChangeNoAlarm}
                                />
                                <label htmlFor="patientChild-noMonitoringRequired" className={`mandatoryCheckbox ${(!this.state.noMonitoringRequired) ? 'empty' : 'filled'}`}>
                                    {t([
                                        'The patient doesn’t require continuous monitoring with physiological alarm system.',
                                        'patient.noMonitoringRequired'
                                    ])}
                                </label>
                            </div>
                        </div>
                    }
                    {state.displayOldChild &&
                        <div className="fieldWrapper">
                            <div className="checkboxFieldWrapper">
                                <input
                                    type="checkbox"
                                    name="oldChild"
                                    id="patientChild-oldChild"
                                    required
                                    aria-required="true"
                                    value={this.state.oldChild.toString()}
                                    checked={this.state.oldChild}
                                    onChange={this.handleChangeOldChild}
                                />
                                <label htmlFor="patientChild-oldChild" className={`mandatoryCheckbox ${(!this.state.oldChild) ? 'empty' : 'filled'}`}>
                                    {t([
                                        'The intended use does not permit use for patients older than 12 years.',
                                        'patient.oldChild.firstText'
                                    ])}
                                    <br />
                                    {t([
                                        'You will use the device under your own responsibility.',
                                        'patient.oldChild.secondText'
                                    ])}
                                </label>
                            </div>
                        </div>
                    }
                    {state.displayNeoChild &&
                        <div className="fieldWrapper">
                            <div className="checkboxFieldWrapper">
                                <input
                                    type="checkbox"
                                    name="neoChild"
                                    id="patientChild-neoChild"
                                    required
                                    aria-required="true"
                                    value={this.state.neoChild.toString()}
                                    checked={this.state.neoChild}
                                    onChange={this.handleChangeNeoChild}
                                />

                                <label htmlFor="patientChild-neoChild" className={`mandatoryCheckbox ${(!this.state.neoChild) ? 'empty' : 'filled'}`}>
                                    {t([
                                        'The intended use does not permit use for patients younger than 28 days.',
                                        'patient.neoChild.firstText'
                                    ])}
                                    <br />
                                    {t([
                                        'You will use the device under your own responsibility.',
                                        'patient.neoChild.secondText'
                                    ])}
                                </label>
                            </div>
                        </div>
                    }
                    <div className="step-form-actions">
                        <Button className="button-create" disabled={!this.isFilled()} loading={props.loading}>
                            {(props.patient) ? t(['Save', 'patient.save']) : t(['Next', 'patient.next'])}
                        </Button>
                    </div>
                </form>
            </div>
        );
    }

    handleChangeFirstName(e) {
        this.setState(state => ({
            ...state,
            firstName: e.target.value,
        }), function () {
            this.checkNotSavedDataChild();
        });
    }

    handleChangeLastName(e) {
        this.setState(state => ({
            ...state,
            lastName: e.target.value,
        }), function () {
            this.checkNotSavedDataChild();
        });
    }

    handleChangeBirthDate(e) {
        const newDate/*: DateInputValue */ = e.target.value;
        
        // Entered birthdate is valid
        if(
            newDate.year &&
            `${newDate.year}` !== '' &&
            `${newDate.year}`.length === 4 && !isNaN(newDate.year) &&
            `${newDate.month}` !== '' && !isNaN(newDate.month) &&
            `${newDate.day}` !== ''
        ) {
            const selectedBirthDate = new Date(`${newDate.year}-${newDate.month}-${newDate.day}`);

            // To check that the date has been sucessfully parsed, check that the getTime() does not return NaN
            if(!isNaN(selectedBirthDate.getTime())) {
                const today = NewDateUtil();
                const oldChildDate = subYears(today, 13);
                const neoChildDate = subDays(today, 28);

                oldChildDate.setHours(selectedBirthDate.getHours(), selectedBirthDate.getMinutes(), selectedBirthDate.getSeconds(), selectedBirthDate.getMilliseconds());
                neoChildDate.setHours(selectedBirthDate.getHours(), selectedBirthDate.getMinutes(), selectedBirthDate.getSeconds(), selectedBirthDate.getMilliseconds());

                this.setState(state => ({
                    ...state,
                    isBirthDateValid: true,
                    birthDate: newDate,
                    displayOldChild: selectedBirthDate <= oldChildDate,
                    displayNeoChild: selectedBirthDate > neoChildDate,
                }), () => {
                    this.checkNotSavedDataChild();
                });
            }
            else {
                this.setState(state => ({
                    ...state,
                    isBirthDateValid: false,
                    birthDate: newDate,
                    displayOldChild: false,
                    displayNeoChild: false,
                }), () => {
                    this.checkNotSavedDataChild();
                });
            }
        }
        else {
            this.setState(state => ({
                ...state,
                isBirthDateValid: false,
                birthDate: newDate,
                displayOldChild: false,
                displayNeoChild: false,
            }), function () {
                this.checkNotSavedDataChild();
            });
        }
    }

    handleChangeSex(e) {
        this.setState(state => ({
            ...state,
            sex: (e.target.value === '') ? '' : parseInt(e.target.value),
        }), function () {
            this.checkNotSavedDataChild();
        });
    }

    handleChangeSkinColor(skinType) {
        this.setState(state => ({
            ...state,
            skinType: skinType,
        }), function () {
            this.checkNotSavedDataChild();
        });
    }

    handleChangeNoAlarm() {
        this.setState(state => ({
            ...state,
            noMonitoringRequired: !this.state.noMonitoringRequired,
        }), function () {
            this.checkNotSavedDataChild();
        });
    }

    handleChangeOldChild() {
        this.setState(state => ({
            ...state,
            oldChild: !this.state.oldChild,
        }), function () {
            this.checkNotSavedDataChild();
        });
    }

    handleChangeNeoChild() {
        this.setState(state => ({
            ...state,
            neoChild: !this.state.neoChild,
        }), function () {
            this.checkNotSavedDataChild();
        });
    }

    handleSubmit(e) {
        e.preventDefault();

        if(this.props.onSubmit) {
            const patient = {
                id: this.props.patient?.patientId?.id,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                birthDate: {
                    day: parseInt(this.state.birthDate.day),
                    month: parseInt(this.state.birthDate.month),
                    year: parseInt(this.state.birthDate.year),
                },
                sex: this.state.sex,
                skinType: this.state.skinType,
                noMonitoringRequired: this.state.noMonitoringRequired
            };
            if(this.props.onChanged) {
                this.props.onChanged(false);
            }
            this.props.onSubmit(patient);
        }
    }
}

//language=SCSS
PatientChildForm = Styled(PatientChildForm)`
& {
    .patientChild-skinType-selection {
        display:flex;
    }
}
`;

export default withTranslation()(PatientChildForm);
