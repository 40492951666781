import {Gender} from 'gabi-api-js/hcp/hcp_common_pb';
import {$enum} from 'ts-enum-util';

export function parseIntToGender(value: number): Gender {
    for (const gender of $enum(Gender).getEntries()) {
        if (gender[1] === value) {
            return Gender[gender[0]];
        }
    }
    return Gender.GENDER_NOT_SET;
}
