import { models } from '@/store/models/async/backend-api';
import { AsyncStatus } from '@/store/simpler-redux';
import store from '@/store/index';
import { v4 as uuid } from 'uuid';

import { BackendApiError } from './backend-api-error';
import {Auth0Service} from '@/services/auth0-service';
import {NewDateUtil} from '@/util/new-date-util';

let transportService = null;
let componentTesting = false;

/**
 * This services handles the communication with the Backend API.
 * It will store the data in the redux store after the call.
 * The actual calls are not made in this service. It needs a transportService which handles them, whatever the protocol used
 */
const BackendApiService = {
    setTransportService(service) {
        transportService = service;
    },

    setComponentTesting(testing) {
        componentTesting = testing;
    },

    getRequestIdentifier(options) {
        const out = {};
        const keys = Object.keys(options);
        keys.sort();
        keys.forEach(key => {
            if (!(typeof(options[key]) === 'undefined' || options[key] === null)) {
                out[key] = options[key];
            }
        });
        delete out['cached'];
        delete out['shouldComponentReload'];
        delete out['allowError'];
        return JSON.stringify(out);
    },

    /**
     * Handler of any request.
     * @param options Options of the request:
     * - modelName: (required) name of the model to request
     * - method: (required) method to call : create | readAll | readOne | update | delete | action
     * - id: (only: readOne, update, delete, action) if provided, id of the entity in the model to request
     * - filter: (only: readAll) json-compatible object for the filter query
     * - payload: (only: create, update, action)
     * - cached: (default true; only: readAll, readOne) caches the result of the request for future use. If false, forces the request to be executed again
     * - ttl: (default 1hour; only: readAll, readOne) time to live in cache for future calls, in seconds. Set to 0 to disable cache
     * @return {Promise<*>} Whatever the transport service will return
     */
    getRequest(options) {
        const requestIdentifier = this.getRequestIdentifier(options);

        options = Object.assign({
            cached: true,
            ttl: 3600,
            filter: null,
        }, options);

        if (options.cached !== false) {
            options.cached = true;
        }

        if (options.cached && ['readAll', 'readOne'].includes(options.method)) {
            const entry = StoreSubService.__getStoreEntry(requestIdentifier);
            if (entry) {
                if (entry.date.getTime()/1000 + entry.ttl < NewDateUtil().getTime()/1000) {
                    //StoreSubService.__removeStoreEntry(requestIdentifier);
                }
                else if (entry.data) {
                    console.log('FROM CACHE ', requestIdentifier);
                    return Promise.resolve(entry.data);
                }
            }

            /*const currentState = store.getState()[`api-${modelName}`];
            if (currentState && path === currentState.meta.uri) {
                return Promise.resolve(currentState.data);
            }*/
            // TODO fetchAll cached
        }

        StoreSubService.__prepareRequest(requestIdentifier, options);
        if(componentTesting) {
            return (
                transportService.getRequest('', options)
                    .then(data => {
                        StoreSubService.__setStoreResponse(requestIdentifier, data);
                        return data;
                    })
                    .catch(err => {
                        console.error(err);
                        StoreSubService.__setStoreError(requestIdentifier, errorHandler(err));
                        throw err;
                    })
            );
        }
        else {
            return (
                Auth0Service.getAccessToken()
                    .then(accessToken => transportService.getRequest(accessToken, options))
                    .then(data => {
                        StoreSubService.__setStoreResponse(requestIdentifier, data);
                        return data;
                    })
                    .catch(err => {
                        console.error(err);
                        StoreSubService.__setStoreError(requestIdentifier, errorHandler(err));
                        throw err;
                    })
            );
        }
        
    },

    /**
     * Handler of any request.
     * @param options Options of the request:
     * - modelName: (required) name of the model to request
     * - method: (required) method to call : create | readAll | readOne | update | delete | action
     * - id: (only: readOne, update, delete, action) if provided, id of the entity in the model to request
     * - filter: (only: readAll) json-compatible object for the filter query
     * - payload: (only: create, update, action)
     * - cached: (default true; only: readAll, readOne) caches the result of the request for future use. If false, forces the request to be executed again
     * - ttl: (default 1hour; only: readAll, readOne) time to live in cache for future calls, in seconds. Set to 0 to disable cache
     * @return {Promise<*>} Whatever the transport service will return
     */
    getRequestWithoutToken(options) {
        const requestIdentifier = this.getRequestIdentifier(options);

        options = Object.assign({
            cached: true,
            ttl: 3600,
            filter: null,
        }, options);

        if (options.cached !== false) {
            options.cached = true;
        }

        if (options.cached && ['readAll', 'readOne'].includes(options.method)) {
            const entry = StoreSubService.__getStoreEntry(requestIdentifier);
            if (entry) {
                if (entry.date.getTime()/1000 + entry.ttl < NewDateUtil().getTime()/1000) {
                    //StoreSubService.__removeStoreEntry(requestIdentifier);
                }
                else if (entry.data) {
                    console.log('FROM CACHE ', requestIdentifier);
                    return Promise.resolve(entry.data);
                }
            }

            /*const currentState = store.getState()[`api-${modelName}`];
            if (currentState && path === currentState.meta.uri) {
                return Promise.resolve(currentState.data);
            }*/
            // TODO fetchAll cached
        }

        StoreSubService.__prepareRequest(requestIdentifier, options);
        return (
            transportService.getRequest('', options)
                .then(data => {
                    StoreSubService.__setStoreResponse(requestIdentifier, data);
                    return data;
                })
                .catch(err => {
                    console.error(err);
                    StoreSubService.__setStoreError(requestIdentifier, errorHandler(err));
                    throw err;
                })
        );
    },

    /**
     * Reads all elements of a model matching a filter (all if no filter provided)
     * @param modelName the name of the model to read
     * @param filter json-compatible object for the filter query
     * @param options Options of the request:
     * - cached: (default true) caches the query for future use. If false, forces the query to be executed again
     * - ttl: (default 1hour) time to live in cache for future calls, in seconds. Set to 0 to disable cache
     * @return {Promise<*>} Whatever the transport service will return
     */
    fetchAll(modelName, domainName, filter, options) {
        return (
            this.getRequest({
                ...options,
                modelName: modelName,
                domain: domainName,
                method: 'readAll',
            })
        );
    },

    /**
     * Reads one element of a model, identified by its id.
     * @param modelName the name of the model to read
     * @param domainName the domain where the modelName is located
     * @param id id of the entity to read
     * @param options Options of the request:
     * - cached: (default true) caches the query for future use. If false, forces the query to be executed again
     * - ttl: (default 1hour) time to live in cache for future calls, in seconds. Set to 0 to disable cache
     * @return {Promise<*>} Whatever the transport service will return
     */
    fetchOne(modelName, domainName, id, options) {
        return (
            this.getRequest(Object.assign({
                modelName: modelName,
                domain: domainName,
                method: 'readOne',
                id: id,
            }, options))
        );
    },

    /**
     * Special case, the user loggedin has his own call, because of its
     */
    /*fetchMe(options = {}) {
        options = Object.assign({
            silent: false,
        }, options);
        const model = specificModels.me;
        const curState = store.getState()['me'];

        if (!curState.meta || curState.meta.status !== AsyncStatus.FETCHING) {
            if (!options.silent) {
                store.dispatch(model.actions.setMeta({
                    status: AsyncStatus.FETCHING,
                }));
            }

            return (
                authService.getUser()
                .then(user => {
                    console.log('authenticated!', user.user_id, user);
                    if (!user) {
                        throw new Error('undefined data');
                    }
                    store.dispatch(model.actions.set(user, null, { status: AsyncStatus.FETCHED }));
                    return user;
                })
                .catch(err => {
                    store.dispatch(model.actions.set(null, err, { status: AsyncStatus.ERROR }));
                    throw err;
                })
            );
        }
    },*/

    _create(modelName, route, data) {
        const model = models[modelName];
        if (!route) {
            console.error(`Unknown API route for model '${modelName}'`);
        }
        if (!model) {
            return Promise.reject(new Error(`Unknown model '${modelName}'`));
        }

        const pendingAction = {
            type: 'create',
            id: uuid(),
        };

        return (
            Promise.resolve()
                .then(() => {
                    store.dispatch(model.actions.addPendingAction(pendingAction));
                    return true;
                })
                .then(() => this.transportService.makeRequest({
                    modelName: modelName,
                    method: 'create',
                    payload: data
                }))
                .then((response) => {
                    if (response) {
                        console.debug('Created', response);
                        //On success, add new entity in the store
                        StoreSubService.__setStoreOne(`api-${modelName}`, response);
                        store.dispatch(model.actions.removePendingAction(pendingAction));
                        return response;
                    }
                    else {
                        store.dispatch(model.actions.removePendingAction(pendingAction));
                        return false;
                    }
                })
                .catch(err => {
                    console.error(err);
                    store.dispatch(model.actions.removePendingAction(pendingAction));

                    throw err;
                })
        );
    },

    create(modelName, data) {
        // eslint-disable-next-line no-undef
        const route = getRoute(modelName);
        return this._create(modelName, route, data);
    },

    /*createInParent(modelName, parentId, data) {
        // eslint-disable-next-line no-undef
        const route = getRoute(modelName);
        route.path = route.path.replace(':parentId', parentId);
        return this._create(modelName, route, data);
    },*/

    update(modelName, id, data, options) {
        // eslint-disable-next-line no-undef
        const route = getRoute(modelName);
        const model = models[`api-${modelName}`];
        const silent = options.silent;
        if (!route) {
            console.error(`Unknown API route for model '${modelName}'`);
        }
        if (!model) {
            return Promise.reject(new Error(`Unknown model '${modelName}'`));
        }

        const pendingAction = {
            type: 'update',
            objectId: id,
            id: uuid(),
        };

        return (
            Promise.resolve()
                .then(() => {
                    if (!silent) {
                        store.dispatch(model.actions.addPendingAction(pendingAction));
                    }
                    return true;
                })
                .then(() => this.transportService.makeRequest({
                    modelName: modelName,
                    method: 'update',
                    id: id,
                }))
                .then((response) => {
                    if (response) {
                        StoreSubService.__setStoreOne(`api-${modelName}`, response);
                        if (!silent) {
                            store.dispatch(model.actions.removePendingAction(pendingAction));
                        }
                        return response;
                    }
                    else {
                        if (!silent) {
                            store.dispatch(model.actions.removePendingAction(pendingAction));
                        }
                        return false;
                    }
                })
                .catch(err => {
                    if (!silent) {
                        store.dispatch(model.actions.removePendingAction(pendingAction));
                    }
                    throw err;
                })
        );
    },

    delete(modelName, id) {
        // eslint-disable-next-line no-undef
        const route = getRoute(modelName);
        const model = models[`api-${modelName}`];
        if (!route) {
            console.error(`Unknown API route for model '${modelName}'`);
        }
        if (!model) {
            return Promise.reject(new Error(`Unknown model '${modelName}'`));
        }

        const pendingAction = {
            type: 'delete',
            objectId: id,
            id: uuid(),
        };

        return (
            Promise.resolve()
                .then(() => {
                    store.dispatch(model.actions.addPendingAction(pendingAction));
                    return true;
                })
                .then(() => this.transportService.makeRequest({
                    modelName: modelName,
                    id: id,
                    method: 'delete',
                }))
                .then(() => {
                    StoreSubService.__removeStoreOne(`api-${modelName}`, id);
                    store.dispatch(model.actions.removePendingAction(pendingAction));
                    return true;
                })
                .catch(err => {
                    store.dispatch(model.actions.removePendingAction(pendingAction));
                    throw err;
                })
        );
    },
};

const StoreSubService = {
    __prepareRequest(requestIdentifier, options) {
        store.dispatch(models['backend-api'].actions.prepare({
            request: requestIdentifier,
            status: AsyncStatus.FETCHING,
            ttl: options.ttl,
            date: NewDateUtil(),
        }));
    },

    __getStoreEntry(requestIdentifier) {
        return store.getState()['backend-api'][requestIdentifier];
    },

    __setStoreResponse(requestIdentifier, data) {
        const model = models['backend-api'];
        //console.debug('SET ALL', requestIdentifier);
        //data = arrayToMap(data, item => item.id);

        store.dispatch(model.actions.update({
            request: requestIdentifier,
            status: AsyncStatus.FETCHED,
            data: data,
        }));
    },

    __setStoreError(request, error) {
        const model = models['backend-api'];
        console.error('ERR', request, error);
        store.dispatch(model.actions.update({
            request: request,
            status: AsyncStatus.ERROR,
            error: error,
            data: null,
        }));
    },

    /*__removeStoreEntry(request) {
        const model = models['backend-api'];
        store.dispatch(model.actions.remove({
            request: request,
        }));
    },*/
};

function errorHandler(err) {
    if (!(err instanceof BackendApiError)) {
        let status = err.status || -1;
        let message = err.message || 'No error message';
        let data = {};
    
        //Got response
        if (err.response) {
            status = err.response.status || status;
            data = err.response.data || data;
        }
        return new BackendApiError(status, message, data);
    }
    else {
        return err;
    }
}

window.BackendApiService = BackendApiService;

export { BackendApiService };
//----------------------------------------------------------------------------------------------------------------------
