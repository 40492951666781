import { asyncModel } from '@/store/simpler-redux';

export default asyncModel({
    name: 'auth',
    state: null,
    reducers: {
    },
    actions: {
    },
});
