import {VirtualCareSignalType} from '@/types/virtual-care-signal-type';
import {
    Conclusion,
    Gender,
    Language,
    Sensibility,
    Sex,
    SignalEventConfiguration,
    SignalsEventConfiguration,
    SignalType,
    SkinType,
    Threshold
} from 'gabi-api-js/vct/vct_common_pb';
import VirtualCareGender from '@/types/virtual-care-gender';
import VirtualCareLanguage from '@/types/virtual-care-language';
import VirtualCareSex from '@/types/virtual-care-sex';
import VirtualCareSkinType from '@/types/virtual-care-skin-type';
import VirtualCareApiDate from '@/types/virtual-care-api-date';
import VirtualCareApiTime from '@/types/virtual-care-api-time';
import VirtualCareApiFullDate from '@/types/virtual-care-api-full-date';
import VirtualCareSignalsEventConfiguration from '@/types/virtual-care-signals-event-configuration';
import VirtualCareConclusion from '@/types/virtual-care-conclusion';

/* PARSE DATE */

export const parseJsDateToVirtualCareApiDate = (jsDate: Date):VirtualCareApiDate => {
    return new VirtualCareApiDate(
        jsDate.getFullYear(),
        jsDate.getMonth() + 1,
        jsDate.getDate()
    );
};

export const parseJsDateToVirtualCareApiTime = (jsDate: Date):VirtualCareApiTime => {
    return new VirtualCareApiTime(
        jsDate.getHours(),
        jsDate.getMinutes(),
        jsDate.getSeconds(),
        jsDate.getMilliseconds(),
        jsDate.getTimezoneOffset(),
    );
};

export const parseJsDateToVirtualCareApiFulLDate = (jsDate: Date):VirtualCareApiFullDate => {
    return new VirtualCareApiFullDate(
        parseJsDateToVirtualCareApiDate(jsDate),
        parseJsDateToVirtualCareApiTime(jsDate)
    );
};

/* PARSE SIGNAL TYPE */

export const parseVirtualCareSignalTypeToApiSignalType = (virtualCareSignalType: VirtualCareSignalType):SignalType => {
    switch(virtualCareSignalType) {
    case VirtualCareSignalType.SIGNAL_PR:
        return SignalType.SIGNAL_PR;
    case VirtualCareSignalType.SIGNAL_SPO2:
        return SignalType.SIGNAL_SPO2;
    case VirtualCareSignalType.SIGNAL_RR:
        return SignalType.SIGNAL_RR;
    case VirtualCareSignalType.SIGNAL_ACTIGRAPHY:
        return SignalType.SIGNAL_ACTIGRAPHY;
    }
};

export const parseApiSignalTypeToVirtualCareSignalType = (signalType: SignalType):VirtualCareSignalType => {
    switch(signalType) {
    case SignalType.SIGNAL_PR:
        return VirtualCareSignalType.SIGNAL_PR;
    case SignalType.SIGNAL_SPO2:
        return VirtualCareSignalType.SIGNAL_SPO2;
    case SignalType.SIGNAL_RR:
        return VirtualCareSignalType.SIGNAL_RR;
    case SignalType.SIGNAL_ACTIGRAPHY:
        return VirtualCareSignalType.SIGNAL_ACTIGRAPHY;
    }
};

/* PARSE GENDER */

export const parseVirtualCareGenderToApiGender = (virtualCareGender: VirtualCareGender):Gender => {
    switch(virtualCareGender) {
    case VirtualCareGender.GENDER_NOT_SET:
        return Gender.GENDER_NOT_SET;
    case VirtualCareGender.MALE:
        return Gender.MALE;
    case VirtualCareGender.FEMALE:
        return Gender.FEMALE;
    case VirtualCareGender.OTHER:
        return Gender.OTHER;
    }
};

export const parseApiGenderToVirtualCareGender = (gender: Gender):VirtualCareGender => {
    switch(gender) {
    case Gender.GENDER_NOT_SET:
        return VirtualCareGender.GENDER_NOT_SET;
    case Gender.MALE:
        return VirtualCareGender.MALE;
    case Gender.FEMALE:
        return VirtualCareGender.FEMALE;
    case Gender.OTHER:
        return VirtualCareGender.OTHER;
    }
};

/* PARSE LANGUAGE */

export const parseVirtualCareLanguageToApiLanguage = (virtualCareLanguage: VirtualCareLanguage): Language => {
    switch(virtualCareLanguage) {
    case VirtualCareLanguage.LANGUAGE_NOT_SET:
        return Language.LANGUAGE_NOT_SET;
    case VirtualCareLanguage.ENGLISH:
        return Language.ENGLISH;
    case VirtualCareLanguage.FRENCH:
        return Language.FRENCH;
    case VirtualCareLanguage.SPANISH:
        return Language.SPANISH;
    }
};

export const parseApiLanguageToVirtualCareLanguage = (language: Language): VirtualCareLanguage => {
    switch(language) {
    case Language.LANGUAGE_NOT_SET:
        return VirtualCareLanguage.LANGUAGE_NOT_SET;
    case Language.ENGLISH:
        return VirtualCareLanguage.ENGLISH;
    case Language.FRENCH:
        return VirtualCareLanguage.FRENCH;
    case Language.SPANISH:
        return VirtualCareLanguage.SPANISH;
    }
};

/* PARSE SEX */

export const parseVirtualCareSexToApiSex = (virtualCareSex: VirtualCareSex):Sex => {
    switch(virtualCareSex) {
    case VirtualCareSex.UNKNOW:
        return Sex.UNKNOW;
    case VirtualCareSex.FEMALE:
        return Sex.FEMALE;
    case VirtualCareSex.MALE:
        return Sex.MALE;
    }
};

export const parseApiSexToVirtualCareSex = (sex: Sex):VirtualCareSex => {
    switch(sex) {
    case Sex.UNKNOW:
        return VirtualCareSex.UNKNOW;
    case Sex.FEMALE:
        return VirtualCareSex.FEMALE;
    case Sex.MALE:
        return VirtualCareSex.MALE;
    }
};

/* PARSE SKIN TYPE */

export const parseVirtualCareSkinTypeToApiSkinType = (virtualCareSkinType: VirtualCareSkinType):SkinType => {
    switch(virtualCareSkinType) {
    case VirtualCareSkinType.NOT_SET:
        return SkinType.NOT_SET;
    case VirtualCareSkinType.FITZPATRICK_I:
        return SkinType.FITZPATRICK_I;
    case VirtualCareSkinType.FITZPATRICK_II:
        return SkinType.FITZPATRICK_II;
    case VirtualCareSkinType.FITZPATRICK_III:
        return SkinType.FITZPATRICK_III;
    case VirtualCareSkinType.FITZPATRICK_IV:
        return SkinType.FITZPATRICK_IV;
    case VirtualCareSkinType.FITZPATRICK_V:
        return SkinType.FITZPATRICK_V;
    default:
        return SkinType.NOT_SET;
    }
};

export const parseApiSkinTypeToVirtualCareSkinType = (skinType: SkinType):VirtualCareSkinType => {
    switch(skinType) {
    case SkinType.NOT_SET:
        return VirtualCareSkinType.NOT_SET;
    case SkinType.FITZPATRICK_I:
        return VirtualCareSkinType.FITZPATRICK_I;
    case SkinType.FITZPATRICK_II:
        return VirtualCareSkinType.FITZPATRICK_II;
    case SkinType.FITZPATRICK_III:
        return VirtualCareSkinType.FITZPATRICK_III;
    case SkinType.FITZPATRICK_IV:
        return VirtualCareSkinType.FITZPATRICK_IV;
    case SkinType.FITZPATRICK_V:
        return VirtualCareSkinType.FITZPATRICK_V;
    default:
        return VirtualCareSkinType.NOT_SET;
    }
};

/* PARSE EVENT CONFIGURATION */

export const parseVirtualCareEventConfigurationToApiEventConfiguration = (eventConfiguration: VirtualCareSignalsEventConfiguration):SignalsEventConfiguration => {
    const apiEventConfiguration = new SignalsEventConfiguration();
    const apiEventConfigurationHr = new SignalEventConfiguration();
    const apiEventConfigurationSpo2 = new SignalEventConfiguration();
    const apiEventConfigurationRr = new SignalEventConfiguration();

    apiEventConfigurationHr.setHigh(new Threshold().setValue(eventConfiguration.hr.high.value));
    apiEventConfigurationHr.setLow(new Threshold().setValue(eventConfiguration.hr.low.value));
    apiEventConfigurationHr.setSensibility(new Sensibility().setSeconds(eventConfiguration.hr.sensibility.seconds));

    apiEventConfigurationSpo2.setHigh(new Threshold().setValue(eventConfiguration.spo2.high.value));
    apiEventConfigurationSpo2.setLow(new Threshold().setValue(eventConfiguration.spo2.low.value));
    apiEventConfigurationSpo2.setSensibility(new Sensibility().setSeconds(eventConfiguration.spo2.sensibility.seconds));

    apiEventConfigurationRr.setHigh(new Threshold().setValue(eventConfiguration.rr.high.value));
    apiEventConfigurationRr.setLow(new Threshold().setValue(eventConfiguration.rr.low.value));
    apiEventConfigurationRr.setSensibility(new Sensibility().setSeconds(eventConfiguration.rr.sensibility.seconds));

    apiEventConfiguration.setHr(apiEventConfigurationHr);
    apiEventConfiguration.setSpo2(apiEventConfigurationSpo2);
    apiEventConfiguration.setRr(apiEventConfigurationRr);

    return apiEventConfiguration;
};

export const parseVirtualCareConclusionToApiConclusion = (conclusion: VirtualCareConclusion):Conclusion => {
    const apiConclusion = new Conclusion();

    apiConclusion.setConclusion(conclusion.conclusion);

    return apiConclusion;
};
