import {PatientPermissionManagementQueryServiceClient} from 'gabi-api-js/patient/patient_query_grpc_web_pb';
import {PatientPermissionManagementCommandServiceClient} from 'gabi-api-js/patient/patient_command_grpc_web_pb';
import {PatientId} from 'gabi-api-js/signal/signal_common_pb.js';
import grpcRequest from '@/services/api-requests/grpc-request.js';
import {AccessRequest} from 'gabi-api-js/patient/patient_command_pb.js';
import {GroupId} from 'gabi-api-js/group/group_common_pb.js';

const serviceQueryClient = PatientPermissionManagementQueryServiceClient;
const serviceCommandClient = PatientPermissionManagementCommandServiceClient;

export class PatientPermissionManagementService {
    async getRequest(idToken, options) {
        options = Object.assign({
            modelName: null,
            method: null, // 'create' | 'readOne' | 'readAll' | 'update' | 'delete'
            id: null,
            filter: null, // Mutually exlusive with id
            payload: null,
        }, options);

        switch(options.modelName) {
        case 'getPatientGroups': {
            return this.fetchPatientGroups(idToken, options.data);
        }
        case 'patientGroupGrantAccess': {
            return this.patientGroupGrantAccess(idToken, options.data);
        }
        case 'patientGroupRevokeAccess': {
            return this.patientGroupRevokeAccess(idToken, options.data);
        }
            
        default: {
            console.error('UNKNOWN REQUEST', options.modelName);
        }
        }
    }

    fetchPatientGroups(idToken, data) {
        const req = new PatientId();
        req.setId(data.patientId);
        return (
            grpcRequest(idToken, serviceQueryClient, 'findGroups', req)
                .then(allResults => {
                    return allResults;
                })
        );
    }

    patientGroupGrantAccess(idToken, data) {
        const req = new AccessRequest();

        req.setPatientId(new PatientId().setId(data.patientId));
        req.setGroupId(new GroupId().setId(data.groupId));

        return (
            grpcRequest(idToken, serviceCommandClient, 'grantAccess', req)
                .then(result => {
                    return result;
                })
        );
    }

    patientGroupRevokeAccess(idToken, data) {
        const req = new AccessRequest();

        req.setPatientId(new PatientId().setId(data.patientId));
        req.setGroupId(new GroupId().setId(data.groupId));

        return (
            grpcRequest(idToken, serviceCommandClient, 'revokeAccess', req)
                .then(result => {
                    return result;
                })
        );
    }
}
