import {SignalService} from '@/services/api-requests/signal-requests-service.js';
import {PatientService} from '@/services/api-requests/patient-requests-service.js';
import {HcpService} from '@/services/api-requests/hcp-requests-service.js';
import {CaregiverService} from '@/services/api-requests/caregiver-requests-service.js';
import {GroupService} from '@/services/api-requests/group-requests-service.js';
import {PatientPermissionManagementService} from '@/services/api-requests/patient-permission-management-requests-service.js';
import {PrivacyService} from '@/services/api-requests/privacy-requests-service.js';
import {VirtualCareService} from '@/services/api-requests/virtual-care-requests-service.ts';

export class FirestoreTransportService {

    async getRequest(/*String*/idToken, /*Object*/options) {
        options = Object.assign({
            modelName: null,
            domain: null,
            method: null, // 'create' | 'readOne' | 'readAll' | 'update' | 'delete'
            id: null,
            filter: null, // Mutually exlusive with id
            payload: null,
        }, options);

        if(options.domain) {
            let domainService = '';

            // Set service according to specified domain
            switch(options.domain) {
            case 'signal': {
                domainService = new SignalService();
                break;
            }
            case 'patient': {
                domainService = new PatientService();
                break;
            }
            case 'caregiver': {
                domainService = new CaregiverService();
                break;
            }
            case 'hcp': {
                domainService = new HcpService();
                break;
            }
            case 'group': {
                domainService = new GroupService();
                break;
            }
            case 'patientPermission': {
                domainService = new PatientPermissionManagementService();
                break;
            }
            case 'privacy': {
                domainService = new PrivacyService();
                break;
            }
            case 'virtualCare': {
                domainService = new VirtualCareService();
                break;
            }
            }

            return domainService.getRequest(idToken, options)
                .then(data => {
                    return data;
                })
                .catch(err => {
                    console.error(err);
                    throw err;
                });
        } else {
            console.error('UNKNOWN REQUEST', options.modelName);
        }
    }

    async /*Promise*/ postRequest(/*String*/ idToken, /*String*/ method) {
        switch (method) {
        default: {
            throw new Error('Unknown action');
        }
        }
    }
}
