import React from 'react';
import PropTypes from 'prop-types';
import Styled from 'styled-components';
import {colorPalette} from '@/themes/darkmode.js';
import infoIcon from '@/public/icons/info-icon.svg';

class SuccessMessage extends React.PureComponent {
    static propTypes = {
        className: PropTypes.string,
        successMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
        text: PropTypes.string,
    };

    render() {
        const props = this.props;

        return (
            <div className={`success-message ${props.className}`}>
                {props.successMessage}<br />
                {props.text}
            </div>
        );
    }
}

//language=SCSS
SuccessMessage = Styled(SuccessMessage)`
& {
    padding: 10px 10px 10px 60px;
    border-radius: 5px;
    background-color:${colorPalette.success};
    background-image: url(${infoIcon});
    background-repeat: no-repeat;
    background-position: 20px center;
    background-size: 25px;
    margin:10px 0;
}
`;

export { SuccessMessage };
