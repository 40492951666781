import { PatientId } from 'gabi-api-js/signal/signal_common_pb';
import { SignalQueryServiceClient } from 'gabi-api-js/signal/signal_query_grpc_web_pb';
import {
    DateQuery,
    SignalPerDayQuery,
    SignalPerHourQuery,
    SignalSampleQuery,
    SignalType, TimeQuery,
    SignalQuery,
    SignalEventsQuery,
    SignalAverageForDayRequest, AverageEventsPerHoursForDayRequest, UnifiedDurationRequest
} from 'gabi-api-js/signal/signal_query_pb';

import grpcRequest from '@/services/api-requests/grpc-request';
import {
    buildDate,
    buildFullDate,
    buildSignalType, formatApiDateToJSDate, formatApiFullDateToJSDate,
    formatJSDateToApiDate,
    formatJSDateToApiFullDate,
} from '@/services/api-requests/requests-utils';
import {NewDateUtil} from '@/util/new-date-util';

const serviceQueryClient = SignalQueryServiceClient;

export class SignalService {
    async getRequest(idToken, options) {
        options = Object.assign({
            modelName: null,
            method: null, // 'create' | 'readOne' | 'readAll' | 'update' | 'delete'
            id: null,
            filter: null, // Mutually exlusive with id
            payload: null,
        }, options);

        switch(options.modelName) {
        case 'getSignalAverageForDay' : {
            return this.getSignalAverageForDay(idToken, options.data);
        }
        case 'getSignalAverageForPeriod' : {
            return this.getSignalAverageForPeriod(idToken, options.data);
        }
        case 'getSignalsUnifiedDuration' : {
            return this.getSignalsUnifiedDuration(idToken, options.data);
        }
        case 'getAverageEventsPerHoursForDay' : {
            return this.getAverageEventsPerHoursForDay(idToken, options.data);
        }
        case 'getAverageEventsPerHoursForPeriod' : {
            return this.getAverageEventsPerHoursForPeriod(idToken, options.data);
        }
        case 'getEventsPerHour' : {
            return this.getEventsPerHour(idToken, options.data);
        }
        case 'getEventsLowPerHour' : {
            return this.getEventsLowPerHour(idToken, options.data);
        }
        case 'getEventsHighPerHour' : {
            return this.getEventsHighPerHour(idToken, options.data);
        }
        case 'getEventsPerDay' :
        case 'getEventsLowPerDay' :
        case 'getEventsHighPerDay' : {
            return this.getEventsPerDay(idToken, options.modelName, options.data);
        }
        case 'getTypedEventsList' : {
            return this.getTypedEventsList(idToken, options.data);
        }
        case 'getEventsForPeriod' : {
            return this.getEventsForPeriod(idToken, options.data);
        }
        case 'getStatisticsPerDay' : {
            return this.getStatisticsPerDay(idToken, options.data);
        }
        case 'getAggregatedSignal' : {
            return this.getAggregatedSignal(idToken, options.data);
        }
        case 'getRecordingDuration' : {
            return this.getRecordingDuration(idToken, options.data);
        }
        case 'getSignal': {
            return this.getSignal(idToken, options.data);
        }
        case 'getSignalSample': {
            return this.getSignalSample(idToken, options.data);
        }
        case 'daysWithData' : {
            return this.fetchDaysWithData(idToken, options.id);
        }

        default: {
            console.error('UNKNOWN REQUEST', options.modelName);
        }
        }
    }

    getSignalAverageForDay(idToken, data) {
        const req = new SignalAverageForDayRequest();
        req.setPatientId(new PatientId().setId(data.patientId));
        req.setDay(buildDate(formatJSDateToApiDate(data.day)));
        req.setType(buildSignalType(data.signalType));
        req.setNbPreviousDays(data.nbPreviousDays);
        return (
            grpcRequest(idToken, serviceQueryClient, 'getSignalAverageForDay', req)
                .then(result => {
                    return result;
                })
        );
    }

    getSignalAverageForPeriod(idToken, data) {
        const req = new SignalQuery();
        req.setPatientId(new PatientId().setId(data.patientId));
        req.setType(buildSignalType(data.signalType));
        req.setFrom(buildDate(formatJSDateToApiDate(data.dateFrom)));
        req.setTo(buildDate(formatJSDateToApiDate(data.dateTo)));
        return (
            grpcRequest(idToken, serviceQueryClient, 'getSignalAverageForPeriod', req)
                .then(result => {
                    return result;
                })
        );
    }
    
    getSignalsUnifiedDuration(idToken, data) {
        const req = new UnifiedDurationRequest();
        req.setPatientId(new PatientId().setId(data.patientId));
        req.setFrom(buildDate(formatJSDateToApiDate(data.dateFrom)));
        req.setTo(buildDate(formatJSDateToApiDate(data.dateTo)));
        req.setSignalsList(data.signalTypes);
        return (
            grpcRequest(idToken, serviceQueryClient, 'getSignalsUnifiedDuration', req)
                .then(result => {
                    return result;
                })
        );
    }

    getAverageEventsPerHoursForDay(idToken, data) {
        const req = new AverageEventsPerHoursForDayRequest();
        req.setPatientId(new PatientId().setId(data.patientId));
        req.setDay(buildDate(formatJSDateToApiDate(data.day)));
        req.setSignal(buildSignalType(data.signalType));
        req.setType(data.alertType);
        req.setNbPreviousDays(data.nbPreviousDays);
        return (
            grpcRequest(idToken, serviceQueryClient, 'getAverageEventsPerHoursForDay', req)
                .then(result => {
                    return result;
                })
        );
    }
    
    getAverageEventsPerHoursForPeriod(idToken, data) {
        const req = new SignalEventsQuery();
        req.setPatientId(new PatientId().setId(data.patientId));
        req.setType(data.alertType);
        req.setFrom(buildDate(formatJSDateToApiDate(data.dateFrom)));
        req.setTo(buildDate(formatJSDateToApiDate(data.dateTo)));
        req.setSignal(buildSignalType(data.signalType));
        return (
            grpcRequest(idToken, serviceQueryClient, 'getAverageEventsPerHoursForPeriod', req)
                .then(result => {
                    return result;
                })
        );
    }
    
    getEventsPerHour_(requestName, idToken, data) {
        const req = new SignalPerHourQuery();
        req.setPatientId(new PatientId().setId(data.patientId));
        req.setSignalType(buildSignalType(data.signalType));
        req.setDate(buildDate(formatJSDateToApiDate(data.date)));
        return (
            grpcRequest(idToken, serviceQueryClient, requestName, req)
                .then(allResults => {
                    allResults.eventsPerHourList.forEach(item => {
                        item.time = formatApiFullDateToJSDate(item.time);
                    });
                    return allResults;
                })
        );
    }

    getEventsPerHour(idToken, data) {
        return this.getEventsPerHour_('getEventsPerHour', idToken, data);
    }

    getEventsLowPerHour(idToken, data) {
        return this.getEventsPerHour_('getEventsLowPerHour', idToken, data);
    }

    getEventsHighPerHour(idToken, data) {
        return this.getEventsPerHour_('getEventsHighPerHour', idToken, data);
    }
    
    getEventsPerDay(idToken, modelName, data) {
        const req = new SignalPerDayQuery();
        req.setPatientId(new PatientId().setId(data.patientId));
        req.setSignalType(buildSignalType(data.signalType));
        req.setFrom(buildDate(formatJSDateToApiDate(data.dateFrom)));
        req.setTo(buildDate(formatJSDateToApiDate(data.dateTo)));
        return (
            grpcRequest(idToken, serviceQueryClient, modelName, req)
                .then(result => {
                    return result;
                })
        );
    }

    getEventsForPeriod(idToken, data) {
        const req = new SignalPerDayQuery();
        req.setPatientId(new PatientId().setId(data.patientId));
        req.setSignalType(buildSignalType(data.signalType));
        req.setFrom(buildDate(formatJSDateToApiDate(data.dateFrom)));
        req.setTo(buildDate(formatJSDateToApiDate(data.dateTo)));
        return (
            grpcRequest(idToken, serviceQueryClient, 'getEventsForPeriod', req)
                .then(result => {
                    return result;
                })
        );
    }

    getTypedEventsList(idToken, data) {
        const req = new DateQuery();
        req.setPatientId(new PatientId().setId(data.patientId));
        req.setDate(buildDate(formatJSDateToApiDate(data.date)));
        return (
            grpcRequest(idToken, serviceQueryClient, 'getTypedEventsList', req)
                .then(result => {
                    return result;
                })
        );
    }

    getStatisticsPerDay(idToken, data) {
        const req = new SignalPerDayQuery();
        req.setPatientId(new PatientId().setId(data.patientId));
        req.setSignalType(buildSignalType(data.signalType));
        req.setFrom(buildDate(formatJSDateToApiDate(data.dateFrom)));
        req.setTo(buildDate(formatJSDateToApiDate(data.dateTo)));
        return (
            grpcRequest(idToken, serviceQueryClient, 'getStatisticsPerDay', req)
                .then(result => {
                    return result;
                })
        );
    }

    getAggregatedSignal(idToken, data) {
        const req = new SignalQuery();
        req.setPatientId(new PatientId().setId(data.patientId));
        req.setType(buildSignalType(data.signalType));
        req.setFrom(buildDate(formatJSDateToApiDate(data.dateFrom)));
        req.setTo(buildDate(formatJSDateToApiDate(data.dateTo)));
        return (
            grpcRequest(idToken, serviceQueryClient, 'getAggregatedSignal', req)
                .then(result => {
                    return result;
                })
        );
    }

    getRecordingDuration(idToken, data) {
        const req = new DateQuery();
        req.setPatientId(new PatientId().setId(data.patientId));
        req.setDate(buildDate(formatJSDateToApiDate(data.date)));
        return (
            grpcRequest(idToken, serviceQueryClient, 'getRecordingDuration', req)
                .then(result => {
                    return result;
                })
        );
    }

    getSignal(idToken, data) {
        return Promise.all([
            this.getSignalForSignalType(idToken, data, SignalType.SIGNAL_PR),
            this.getSignalForSignalType(idToken, data, SignalType.SIGNAL_SPO2),
            this.getSignalForSignalType(idToken, data, SignalType.SIGNAL_ACTIGRAPHY),
        ]).then(([ hrData, spo2Data, actigraphy ]) => {
            return {
                rangesList: {
                    hr: [hrData.valueMin, hrData.valueMax],
                    spo2: [spo2Data.valueMin, spo2Data.valueMax],
                    actigraphy: [actigraphy.valueMin, actigraphy.valueMax],
                },
                blocksList: {
                    hr: hrData.blocksList,
                    spo2: spo2Data.blocksList,
                    actigraphy: actigraphy.blocksList,
                },
                eventsList: {
                    hr: hrData.eventsList,
                    spo2: spo2Data.eventsList,
                    actigraphy: actigraphy.eventsList,
                }
            };
        });
    }

    getSignalForSignalType(idToken, data, signalType) {
        const req = new SignalQuery();
        req.setFrom(buildDate(formatJSDateToApiDate(data.from)));
        req.setTo(buildDate(formatJSDateToApiDate(data.to)));
        req.setPatientId(new PatientId().setId(data.patientId));
        req.setType(signalType);
        return grpcRequest(idToken, SignalQueryServiceClient, 'getSignal', req)
            .then(allResults => {
                allResults.blocksList.forEach(item => {
                    item.atOrig = item.at;
                    item.at = formatApiFullDateToJSDate(item.at).getTime();
                });
                allResults.eventsList.forEach(item => {
                    item.from = formatApiFullDateToJSDate(item.from).getTime();
                    item.to = formatApiFullDateToJSDate(item.to).getTime();
                });
                return allResults;
            });
    }

    getSignalSample(idToken, data) {
        const req = new SignalSampleQuery();
        req.setPatientId(new PatientId().setId(data.patientId));
        req.setFrom(buildFullDate(formatJSDateToApiFullDate(data.dateFrom)));
        req.setDurationInSeconds(data.duration);
        req.setType(buildSignalType(data.signalType));
        return grpcRequest(idToken, SignalQueryServiceClient, 'getSignalSample', req)
            .then(allResults => {
                allResults.signalData.blocksList.forEach(item => {
                    item.at = formatApiFullDateToJSDate(item.at).getTime();
                });
                allResults.signalData.eventsList.forEach(item => {
                    item.from = formatApiFullDateToJSDate(item.from).getTime();
                    item.to = formatApiFullDateToJSDate(item.to).getTime();
                });
                allResults.actigraphyData.blocksList.forEach(item => {
                    item.at = formatApiFullDateToJSDate(item.at).getTime();
                });
                return allResults;
            });
    }

    async fetchDaysWithData(idToken, babyId) {
        const req = new TimeQuery();
        req.setPatientId(new PatientId().setId(babyId));

        const fromDateJS = new Date('2021/01/01 00:00:00');
        const toDateJS = NewDateUtil();

        req.setFrom(buildDate(formatJSDateToApiDate(fromDateJS)));
        req.setTo(buildDate(formatJSDateToApiDate(toDateJS)));

        return (
            grpcRequest(idToken, SignalQueryServiceClient, 'getMedicalEventInterval', req)
                .then(allResults => {
                    allResults.dataIntervalList.forEach(medicalEventInterval => {
                        medicalEventInterval.from = formatApiDateToJSDate(medicalEventInterval.from.date).getTime();
                        medicalEventInterval.to = formatApiDateToJSDate(medicalEventInterval.to.date).getTime();
                    });

                    return allResults;
                })
        );
    }
}
