import { model } from '@/store/simpler-redux';

export default model({
    name: 'tutorialStatus',
    state: false,
    reducers: {
        set: (state, data) => {
            return data;
        },
    },
});
