//Root level CSS. Instead of using styled component, it is injected as a style tag.
//This is done to make sure that displaced component are matching the rules (typically, overlays)

import animateCSS from 'animate.css/animate.min.css?inline';
import stylis from 'stylis';

import arrowRight from '@/public/icons/arrow-right-icon.svg';
import eyeIcon from '@/public/icons/eye-icon-full.svg';
import sortDefaultIcon from '@/public/icons/sort-default-icon.svg';
import sortDownIcon from '@/public/icons/sort-down-icon.svg';
import sortUpIcon from '@/public/icons/sort-up-icon.svg';
import {colorPalette, colors, medias, sizes, zIndices} from '@/themes/darkmode';

import '@enykeev/react-virtualized/styles.css';
import 'rc-slider/assets/index.css';

//language=SCSS
const rootCSS = stylis('html body',`

& {
    /*height: 100vh;
    overflow-y: hidden;*/
    font-size: 14px;
    background: ${colors.body.background};
    color: ${colors.body.text};
    font-family: 'Nunito', sans-serif;
    overflow-y: scroll;

    &, .h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
        font-weight: 400;
    }
    
    .h1, h1 {
        font-size: 20px;
        line-height: 48px;
        margin-top:0;
    }
    
    pre {
        color: #fff;
    }
    
    a {
        color: ${colors.link.text};
        cursor: pointer;

        &:hover {
            color: ${colors.link.text};
        }
    }
    /*a:not([href]):not([tabindex]) {
        &:focus, &:hover {
            color: ${colors.link.text};
            text-decoration: underline;
        }
    }*/
    
    .button-link {
        display:inline-block;
        border-radius: 20px;
        color: ${colorPalette.frontColor};
        padding: 5px 15px;
        background: ${colorPalette.thirdBackground};
        &:hover, &:focus {
            text-decoration: none;
            background: ${colorPalette.activeColor};
        }
        &.active {
            background-color: ${colorPalette.activeColorDarker};
        }
    }
    
    .header h1 {
        white-space: nowrap;
    }
    
    .main-content-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        /*@media screen and (${medias.mdMin}) {
            left: ${sizes.menu.widthMd};
        }*/
        @media screen and (${medias.lgMin}) {
            left: ${sizes.menu.widthLg};
        }
        @media screen and (${medias.xlMin}) {
            left: ${sizes.menu.widthXl};
        }
        
        @media print {
            padding: 0;
            margin: 5mm 0 0 0;
        }
        box-decoration-break: clone;
    }
    .main-content-scroll-area, .scroll-area {
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
        
        overflow-y: scroll;
        overflow-x: hidden;
    }
    
    .main-content {
        @media screen and (${medias.mdMin}) {
            //bottom: 80px;
        }
        @media screen and (${medias.lgMin}) {
            top: 0;
        }
        
        @media print {
            padding: 0;
            margin: 0;
        }
        box-decoration-break: clone;

        > .error-boundary {
            margin-top: 100px;
        }
    }

    .sections-wrapper {
        display:flex;
        flex-wrap: wrap;

        .sections-menu {
            margin:0;
            padding:0;
            width:250px;
            border-right:1px solid ${colorPalette.secondBackground};
            height: calc(100vh - 200px);
            a {
                display: block;
                position:relative;
                padding:15px 20px 15px 20px;
                &:hover {
                    background-color:${colorPalette.thirdBackground};
                    border-color:${colorPalette.thirdBackground};
                }
                &.activeStep {
                    background-color: ${colorPalette.info};
                    border-color: ${colorPalette.info};
                    background-image: url(${arrowRight});
                    background-repeat: no-repeat;
                    background-position: right center;
                }
                &.changedStep {
                    &:after {
                        content: "";
                        background-color: ${colorPalette.danger};
                        display: inline-block;
                        vertical-align: middle;
                        width: 5px;
                        height: 5px;
                        border-radius: 8px;
                        position: relative;
                        left: 7px;
                    }
                    &.activeStep:after {
                        background-color: #fff;
                    }
                }
            }

            @media screen and (${medias.smMax}) {
                width:100%;
                height:40px;
                margin-bottom:20px;
                border:none;
                a {
                    display:inline-block;
                    padding: 5px 15px;
                    border:1px solid ${colorPalette.secondBackground};
                    border-collapse: collapse;
                    &:first-child {
                        border-radius: 5px 0 0 5px;
                    }
                    &:last-child {
                        border-radius: 0 5px 5px 0;
                    }
                }
            }
        }

        .sections-content {
            margin-bottom:50px;
            width:calc(100% - 400px);
            margin-left:100px;

            h3 {
                font-size:18px;
                padding-bottom: 5px;
                border-bottom: 1px solid ${colorPalette.info};
                margin-bottom: 20px;
            }

            @media screen and (${medias.smMax}) {
                width:100%;
                margin-left:0;
            }
        }
    }
    
    .info-message {
        background-color:${colorPalette.thirdBackground};
        border-radius: 10px;
        border-left: 10px solid ${colorPalette.activeColor};
        padding: 10px 15px;
        margin-top: 20px;
    }

    .empty-message {
        padding: 10px 20px;
        font-style: italic;
        border-radius: 5px;
        background-color:${colorPalette.thirdBackground};
    }
    }
    
    .debug {
        position: fixed;
        background: red;
        bottom: 0;
        width: 100%;
        z-index: ${zIndices.debug};
    }
    
    //-------------------------------------------------------------------------------------
    //Helper class names
    
    .hidden {
        display:none;
        visibility: hidden;
    }
    
    img {
        max-width: 100%;
    }
    
    strong {
        font-weight: 600;
    }
    
    .clickable {
        cursor: pointer;
    }
    
    .display-block {
        display: block;
    }
    
    .no-margin {
        margin: 0;
    }
    
    .fullw {
        width: 100%;
    }
    .fullh {
        height: 100%;
    }
    
    .link-nocolor {
        color: inherit;
    }
    
    .link-nounderline {
        text-decoration: none;
        &:hover {
            text-decoration: none;
        }
    }
    
    .overflow-visible {
        overflow: visible !important;
    }
    .overflow-hidden {
        overflow: hidden !important;
    }
    .overflow-y-hidden {
        overflow-y: hidden !important;
    }
    .overflow-x-hidden {
        overflow-x: hidden !important;
    }
    
    .text-low-focus {
        color: ${colors.lowFocusText.text};
    }
    
    .text-lower-focus {
        color: ${colors.lowFocusText.text};
        opacity: 0.62;
    }
    
    .pos-relative {
        position: relative;
    }
    
    
    //-------------------------------------------------------------------------------------
    //Global custom styles
    .react-toggle {
        vertical-align: text-bottom;
    }
    
    .align-left {
      text-align: left;
    }
    .align-center {
      text-align: center;
    }
    .align-right {
      text-align: right;
    }
    
    .chart-tooltip {
        span {
            display:block;
        }
        padding: 10px;
        background: ${colors.colorPalette.darkColor};
        box-shadow: 1px 1px 3px rgba(0,0,0, 0.3);
    }
    
    *:not(textarea).form-control {
        height: 32px;
        margin-bottom: 10px;
        padding-top: 2px;
        padding-bottom: 2px;
        color:#fff;
        background-color:${colorPalette.secondBackground};
        border:none;
        &:focus {
            background-color:${colorPalette.clearColor};
            color:${colorPalette.frontColor};
        }
    }
    select.form-control {
        //For some obscure reason, Chrome & firefox are not using the same padding rules as <input> or <textarea> for <select>. So we change it a little bit
        padding-left: 10px;
    }

    table {
        background: ${colorPalette.secondBackground};
        border-collapse: collapse;
        border-radius: 10px;
        //overflow: hidden;
        width:100%;

        th {
            padding: 15px 20px;
            font-weight: normal;
            
            &.signalTableHeader_pulseRate {
                border-top: 5px solid ${colorPalette.signalType.pulseRate};
            }
            &.signalTableHeader_spo2 {
                border-top: 5px solid ${colorPalette.signalType.spo2};
            }
            &.up {
                background-image: url(${sortUpIcon});
            }
            &.down {
                background-image: url(${sortDownIcon});
            }
            &.default {
                background-image: url(${sortDefaultIcon});
            }
            &.up,
            &.default,
            &.down {
                cursor: pointer;
                background-repeat: no-repeat;
                background-position: center right 10px;
                background-size: 8px;
            }
        }

        td {
            padding: 10px 20px;
        }

        td, th {
            border-bottom: 1px solid ${colorPalette.mainBackground};
            border-right: 1px solid ${colorPalette.mainBackground};
            border-left: 1px solid ${colorPalette.mainBackground};
            text-align: left;
            position:relative;
            
            &:last-child {
                border-right: none;
            }
            
            > img {
                margin-right:10px;
            }

            // TODO this is weird to override <span> like that. We should check where this is used and adapt
            span {
                display:block;
                font-size: 11px;
                font-weight: lighter;
            }
            
            &.hoverableCell {
                font-size: 13px;
                strong {
                    font-size:22px;
                }
                .chart-tooltip strong {
                    font-size: 13px;
                }
                &.activeCell {
                    background-color: ${colorPalette.thirdBackground};
                }
            }
            
            > img.warningFlag {
                width: 17px;
                position:absolute;
                right:0;
                top:10px;
            }
            
            &.separatorCellFirst {
                border-left-width: 5px;
            }
            
            &.separatorCellLast {
                border-right-width: 5px;
            }
            
            &.critical {
                background: ${colorPalette.danger};
            }
        }
    }

    .linkCell {
        width: 20px;
        > a, > button {
            border:none;
            display:inline-block;
            background: url(${eyeIcon}) no-repeat center;
            background-size: 20px;
            width:30px;
            height:30px;
            text-indent:-10000px;
            color: transparent;
            border-radius: 5px;
            padding: 20px;

            &:hover {
                background-color: ${colorPalette.activeColor};
            }
        }
    }
    
    .buttonCell {
        width: 20px;
    }
    
    .dateCell {
        width: 150px;
    }
    
    .patientNameCell {
        div {
            display:flex;
            flex-wrap: nowrap;
            align-items: center;
            img {
                margin-right:10px;
            }
            p {
                margin-bottom: 0;
            }
        }
    }
    
    .no-margin {
        margin: 0 !important;
    }
    .no-padding {
        padding: 0 !important;
    }
    
    form {
        .form-section {
            margin:10px 20px 10px 0;
        }
    }
    
    .fieldWrapper {
        padding: 5px 0;
        margin-bottom:10px;
        position:relative;
        label {
            display:block;
            width:100%;
            &.mandatoryField:before {
                content: '*';
                width: 15px;
                height: 15px;
                display: inline-block;
                padding: 0;
                border-radius: 15px;
                margin-right: 5px;
                margin-top: -3px;
                vertical-align: middle;
                text-align: center;
            }
            &.mandatoryField.empty:before {
                background: ${colorPalette.dangerClear};
            }
            &.mandatoryField.filled:before {
                background: ${colorPalette.info};
            }
            &.mandatoryCheckbox:after {
                content: '*';
                width: 15px;
                height: 15px;
                display: inline-block;
                padding: 0;
                border-radius: 15px;
                margin-left: 5px;
                margin-top: -3px;
                vertical-align: middle;
                text-align: center;
            }
            &.mandatoryCheckbox.empty:after {
                background: ${colorPalette.dangerClear};
            }
            &.mandatoryCheckbox.filled:after {
                background: ${colorPalette.info};
            }
        }
        input, select, textarea {
            width: 100%;
            height: 40px;
            border-top-left-radius: 3px;
            border-top-right-radius: 3px;
            border: 1px solid transparent;
            border-bottom: 3px solid ${colorPalette.frontColor};
            background: ${colorPalette.clearColor};
            color: ${colorPalette.frontColor};
            padding: 0 10px;
            &:focus {
                border-bottom: 2px solid ${colorPalette.activeColor};
                outline: ${colorPalette.activeColor} solid 2px;
            }
            &::placeholder {
                color: ${colorPalette.frontColor};
                font-style: italic;
            }
            &.error {
                border-bottom: 3px solid ${colorPalette.danger};
                &:focus {
                    outline: ${colorPalette.danger} solid 2px;
                }
            }
        }
        textarea {
            padding: 10px;
            height:100px;
        }
    }
    .checkboxFieldWrapper {
        display:flex;
        align-items: center;
        padding: 5px 0;
        > input, > div {
            width: 25px;
            flex-grow: 0;
        }
        label {
            flex-grow: 1;
            margin: 0;
            padding-left: 10px;
        }
    }
    .multiFieldWrapper {
        display:flex;
        flex-wrap:wrap;
        .fieldWrapper {
            width:calc(50% - 10px);
            &:first-child {
                margin-right:20px;
            }
            @media screen and (${medias.lgMax}) {
                width:100%;
                &:first-child {
                    margin-right:0;
                }
            }
        }
        > input, > select {
            width:auto;
            margin-right:10px;
        }
    }
    .step-form-actions {
        margin-top:20px;
    }
    
    // Checkbox styling
    input[type=checkbox] {
        accent-color: ${colors.checkbox.background};
        height: 25px;
        width: 25px;
        vertical-align: baseline;
        
        &:hover {
          accent-color: ${colors.checkbox.backgroundHover};
        }
        &:focus {
            accent-color: ${colors.checkbox.background};
            outline: solid 2px ${colors.checkbox.backgroundHover};
        }
    }
    //-------------------------------------------------------------------------------------
    // Random fixtures
    // DateInput z-index fix when inside an overlay
    .opuscapita_date-input__picker-container {
        z-index: ${zIndices.dateInput};
    }
    
    //Helpcrunch iframe hidden when printing
    .helpcrunch-iframe-wrapper {
        @media print {
            display: none;
        }
    }
    
    .intercom-launcher {
        transition: none;
        background: navy !important;
    }
    .intercom-launcher-frame {
        @media print {
            display: none;
        }
        visibility: hidden;
        opacity: 0;
        
        @media screen and (${medias.smMax}) {
            transform: scale(0.5) !important;
            transform-origin: top right !important;
            top: 25px !important;
            bottom: unset !important;
        }
    }
    
    //-------------------------------------------------------------------------------------
    // Bootstrap missing pieces & extensions
    
    .form-control::placeholder {
        color:${colorPalette.frontColor};
        opacity:1;
    }
    
    .container, .container-fluid {
        &.container-no-padding {
            padding: 0;
        }
    }

    .custom-checkbox.custom-control {
        > input {
            width: 0;
        }
    }
    
    @media print {
        .row {
            page-break-inside: auto;
            > * {
                page-break-inside: auto;
            }
            //display: block;
        }
    }
    
    .btn {
        &[disabled] {
            cursor: not-allowed;
        }
    }
    
    .tooltip {
        opacity: 1;
        &.right, &.left {
            margin-left: 3px;
            padding: 0 5px;
            .tooltip-arrow {
                top: 50%;
                margin-top: -5px;
            }
        }
        &.top, &.bottom {
            margin-top: 3px;
            padding: 5px 0;
            .tooltip-arrow {
                left: 50%;
                margin-left: -5px;
            }
        }
        &.right .tooltip-arrow {
            left: 0;
            border-width: 5px 5px 5px 0;
            border-right-color: #000;
        }
        &.left .tooltip-arrow {
            right: 0;
            border-width: 5px 0 5px 5px;
            border-left-color: #000;
        }
        &.top .tooltip-arrow {
            bottom: 0;
            border-width: 5px 5px 0 5px;
            border-top-color: #000;
        }
        &.bottom .tooltip-arrow {
            top: 0;
            border-width: 0 5px 5px 5px;
            border-bottom-color: #000;
        }
    }
    .tooltip-arrow {
        position: absolute;
        width: 0;
        height: 0;
        border-color: transparent;
        border-style: solid;
    }
    .tooltip-inner {
        max-width: 200px;
        padding: 3px 8px;
        color: #fff;
        text-align: center;
        background-color: #000;
        border-radius: 4px;
    }
    
    .text-warning {
        color: ${colorPalette.warning} !important;
    }
    
    .overview-box {
        display:inline-block;
        margin-right:10px;
        background: ${colorPalette.thirdBackground};
        border-radius: 10px;
        padding:10px;
        min-width:140px;
        position:relative;
        span {
            display:block;
        }
        > span:first-child {
            font-size: 25px;
            line-height: 0.8;
            margin-top: 10px;
        }
        > span:nth-child(2) {
            font-size: 12px;
        }
        > span:nth-child(3) {
            font-size: 12px;
            margin-top:20px;
        }
        
        &:last-child {
            margin-right:0;
        }
    }

    @media screen and (max-width: 1600px) {
        .overview-box {
            display:inline-block;
            margin-bottom:10px;
            background: ${colorPalette.thirdBackground};
            border-radius: 10px;
            padding:10px;
            width:100%;
            position:relative;
            > span:first-child {
                font-size: 25px;
                line-height: 0.8;
                display:inline-block;
                margin-right:5px;
            }
            > span:nth-child(2) {
                font-size: 12px;
                display:inline-block;
            }
            > span:nth-child(3) {
                font-size: 12px;
                display:block;
                margin-top:5px;
            }

            &:last-child {
                margin-bottom:0;
            }
        }
    }

    // RECHARTS STYLES
    .recharts-cartesian-grid-horizontal line {
        stroke: ${colorPalette.thirdBackground};
    }

    .recharts-cartesian-axis-ticks {
        tspan {
            fill: #fff;
        }
    }

    .recharts-cartesian-axis-line,
    .recharts-cartesian-axis-tick-line {
        stroke: ${colorPalette.clearColor};
    }

    .recharts-rectangle.recharts-tooltip-cursor,
    .recharts-curve.recharts-tooltip-cursor {
        fill:${colorPalette.clearColor};
        stroke:${colorPalette.clearColor};
    }
}`);

const head = document.head || document.getElementsByTagName('head')[0];
const styleTag = document.createElement('style');
styleTag.type = 'text/css';
if (styleTag.styleSheet) {
    styleTag.styleSheet.cssText = rootCSS;
}
else {
    styleTag.appendChild(document.createTextNode(rootCSS));
}
head.appendChild(styleTag);

const styleTagAnimate = document.createElement('style');
styleTagAnimate.type = 'text/css';
if (styleTagAnimate.styleSheet) {
    styleTagAnimate.styleSheet.cssText = animateCSS;
}
else {
    styleTagAnimate.appendChild(document.createTextNode(animateCSS));
}
head.appendChild(styleTagAnimate);
