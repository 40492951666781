import grpcRequest from '@/services/api-requests/grpc-request.js';
import { Empty } from 'google-protobuf/google/protobuf/empty_pb';
import {HcpQueryServiceClient} from 'gabi-api-js/hcp/hcp_query_grpc_web_pb';
import {HcpCommandServiceClient} from 'gabi-api-js/hcp/hcp_command_grpc_web_pb';
import {
    InvitationToGroup,
    UpdateProgramTypeRequest,
    UpdateTutorialStatusRequest,
} from 'gabi-api-js/hcp/hcp_command_pb.js';
import {HcpProgramEnum} from '@/enum/hcp-program-enum.jsx';
import {
    Address,
    HcpAddition, HcpId, LegalFlags,
    PersonalDetails as HcpPersonalDetails, PhoneNumber,
    ProgramType,
} from 'gabi-api-js/hcp/hcp_common_pb.js';
import {HcpCreationServiceClient} from 'gabi-api-js/hcp/hcp_creation_grpc_web_pb.js';
import {Email} from 'gabi-api-js/hcp/hcp_query_pb.js';
import {GroupId} from 'gabi-api-js/group/group_common_pb.js';
import {buildGender} from '@/services/api-requests/requests-utils.js';

const serviceQueryClient = HcpQueryServiceClient;
const serviceCommandClient = HcpCommandServiceClient;
const creationServiceClient = HcpCreationServiceClient;

export class HcpService {
    async getRequest(idToken, options) {
        options = Object.assign({
            modelName: null,
            method: null, // 'create' | 'readOne' | 'readAll' | 'update' | 'delete'
            id: null,
            filter: null, // Mutually exlusive with id
            payload: null,
        }, options);

        switch(options.modelName) {
        case 'createHcp': {
            return this.createHcp(idToken, options.data);
        }
        case 'getHcpPersonalData': {
            return this.getHcpPersonalData(idToken, options.data);
        }
        case 'updateHcpPersonalDetails': {
            return this.updateHcpPersonalDetails(idToken, options.data);
        }
        case 'getTutorialStatus': {
            return this.getTutorialStatus(idToken);
        }
        case 'updateTutorialStatus': {
            return this.updateTutorialStatus(idToken, options.status);
        }
        case 'getGiftNotificationLastSeen': {
            return this.getGiftNotificationLastSeen(idToken, options.status);
        }
        case 'notifyGiftNotificationSeen': {
            return this.notifyGiftNotificationSeen(idToken);
        }
        case 'getProgramType' : {
            return this.getProgramType(idToken);
        }
        case 'updateProgramType' : {
            return this.updateProgramType(idToken, options.data);
        }
        case 'getHcpChangePasswordLink': {
            return this.getHcpChangePasswordLink(idToken);
        }
        case 'updateHcpEmail': {
            return this.updateHcpEmail(idToken, options.data);
        }
        case 'updateHcpLegalFlags': {
            return this.updateHcpLegalFlags(idToken, options.data);
        }
        case 'findUserByEmail': {
            return this.findUserByEmail(idToken, options.data);
        }
        case 'inviteUserToGroup': {
            return this.inviteUserToGroup(idToken, options.data);
        }
        case 'getPendingInvitations': {
            return this.getPendingInvitations(idToken, options.data);
        }
        case 'getUser': {
            return this.getUser(idToken, options.data);
        }
            
        default: {
            console.error('UNKNOWN REQUEST', options.modelName);
        }
        }
    }

    createHcp(idToken, data) {
        const req = new HcpAddition();
        req.setPersonalDetails(
            new HcpPersonalDetails()
                .setFirstName(data.hcp.firstName)
                .setLastName(data.hcp.lastName)
                .setGender(buildGender(data.hcp.gender))
                .setEmail(data.hcp.email)

                .setAddress(
                    new Address()
                        .setState(data.hcp.address.state)
                        .setCity(data.hcp.address.city)
                        .setCountry(data.hcp.address.country)
                )

                .setPhoneNumber(
                    new PhoneNumber()
                        .setCountryCode(data.hcp.phone.countryCode)
                        .setNumber(data.hcp.phone.number)
                )
        );
        req.setLegalFlags(
            new LegalFlags()
                .setPrivacyPolicy((data.hcp.legalFlags.privacyPolicy) ? 1 : 0)
                .setTermsOfUse((data.hcp.legalFlags.privacyPolicy) ? 1 : 0)
        );
        req.setProgramType(this.buildProgramType(data.hcp.program));
        return (
            grpcRequest(idToken, creationServiceClient, 'add', req)
                .then(result => {
                    return result;
                })
        );
    }

    getHcpPersonalData(idToken, data) {
        const req = new HcpId();
        req.setId(data.hcpId);
        return (
            grpcRequest(idToken, serviceQueryClient, 'get', req)
                .then(result => {
                    return result;
                })
        );
    }

    updateHcpPersonalDetails(idToken, data) {
        const req = new HcpPersonalDetails();
        req.setFirstName(data.hcp.personalDetails.firstName);
        req.setLastName(data.hcp.personalDetails.lastName);
        req.setGender(buildGender(data.hcp.personalDetails.gender));
        req.setAddress(
            new Address()
                .setState(data.hcp.personalDetails.address.state)
                .setCity(data.hcp.personalDetails.address.city)
                .setCountry(data.hcp.personalDetails.address.country)
        );
        req.setPhoneNumber(
            new PhoneNumber()
                .setCountryCode(data.hcp.personalDetails.phoneNumber.countryCode)
                .setNumber(data.hcp.personalDetails.phoneNumber.number)
        );
        return (
            grpcRequest(idToken, serviceCommandClient, 'updatePersonalDetails', req)
                .then(result => {
                    return result;
                })
        );
    }

    getTutorialStatus(idToken) {
        const req = new Empty();
        return (
            grpcRequest(idToken, serviceQueryClient, 'getTutorialStatus', req)
                .then(tutorialStatus => {
                    return tutorialStatus;
                })
        );
    }

    updateTutorialStatus(idToken, status) {
        const req = new UpdateTutorialStatusRequest();
        if(status.demoPatient) {
            req.setDemoPatient(status.demoPatient);
        }
        if(status.guidedTourPatient) {
            req.setGuidedTourPatient(status.guidedTourPatient);
        }
        if(status.guidedTourHealthReport) {
            req.setGuidedTourHealthReport(status.guidedTourHealthReport);
        }
        if(status.guidedTourSignals) {
            req.setGuidedTourSignals(status.guidedTourSignals);
        }
        return (
            grpcRequest(idToken, serviceCommandClient, 'updateTutorialStatus', req)
                .then(tutorialStatus => {
                    return tutorialStatus;
                })
        );
    }

    getGiftNotificationLastSeen(idToken) {
        const req = new Empty();
        return (
            grpcRequest(idToken, serviceQueryClient, 'getGiftNotificationLastSeen', req)
                .then(giftNotificationLastSeen => {
                    return giftNotificationLastSeen;
                })
        );
    }

    notifyGiftNotificationSeen(idToken) {
        const req = new Empty();
        return (
            grpcRequest(idToken, serviceCommandClient, 'notifyGiftNotificationSeen', req)
                .then(giftNotificationLastSeen => {
                    return giftNotificationLastSeen;
                })
        );
    }

    getProgramType(idToken) {
        const req = new Empty();
        return (
            grpcRequest(idToken, serviceQueryClient, 'getProgramType', req)
                .then(result => {
                    return result;
                })
        );
    }

    updateProgramType(idToken, data) {
        const req = new UpdateProgramTypeRequest();
        req.setProgramType(this.buildProgramType(data.program));
        return (
            grpcRequest(idToken, serviceCommandClient, 'updateProgramType', req)
                .then(result => {
                    return result;
                })
        );
    }

    buildProgramType(program) {
        let requestProgram = null;

        if(program === HcpProgramEnum.CARDIO) {
            requestProgram = ProgramType.CARDIAC;
        }
        if(program === HcpProgramEnum.RESPIRATORY) {
            requestProgram = ProgramType.PNEUMO;
        }
        if(program === HcpProgramEnum.RESEARCH) {
            requestProgram = ProgramType.RESEARCH;
        }

        return requestProgram;
    }

    getHcpChangePasswordLink(idToken) {
        const req = new Empty();
        return (
            grpcRequest(idToken, serviceQueryClient, 'getChangePasswordLink', req)
                .then(result => {
                    return result;
                })
        );
    }

    updateHcpEmail(idToken, data) {
        const req = new HcpPersonalDetails();
        req.setEmail(data.hcp.personalDetails.email);
        return (
            grpcRequest(idToken, serviceCommandClient, 'updatePersonalDetails', req)
                .then(result => {
                    return result;
                })
        );
    }

    updateHcpLegalFlags(idToken, data) {
        const req = new LegalFlags();
        req.setPrivacyPolicy(data.legalFlags.privacyPolicy);
        req.setLastName(data.legalFlags.termsOfUse);
        return (
            grpcRequest(idToken, serviceCommandClient, 'updatePersonalDetails', req)
                .then(result => {
                    return result;
                })
        );
    }

    findUserByEmail(idToken, data) {
        const req = new Email();
        req.setEmail(data.userEmail);
        return (
            grpcRequest(idToken, serviceQueryClient, 'findByEmail', req)
                .then(result => {
                    return result;
                })
        );
    }

    inviteUserToGroup(idToken, data) {
        const req = new InvitationToGroup();
        req.setGroupId(new GroupId().setId(data.groupId));
        req.setEmail(data.email);
        return (
            grpcRequest(idToken, serviceCommandClient, 'inviteToGroup', req)
                .then(result => {
                    return result;
                })
        );
    }

    getPendingInvitations(idToken, data) {
        const req = new GroupId();
        req.setId(data.id);
        return (
            grpcRequest(idToken, serviceQueryClient, 'listPendingInvitations', req)
                .then(result => {
                    return result;
                })
        );
    }

    getUser(idToken, data) {
        const req = new HcpId();
        req.setId(data.id);
        return (
            grpcRequest(idToken, serviceQueryClient, 'getPersonalDetails', req)
                .then(result => {
                    return result;
                })
        );
    }
}
