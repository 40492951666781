import React from 'react';
import deepEqual from 'deep-eql';
import Styled from 'styled-components';
import autoBind from 'react-autobind';
import PropTypes from 'prop-types';
import { withTranslation } from 'react-i18next';

import ErrorBoundary from '@/components/errors/error-boundary';
import withRouter from '@/decorators/withRouter';
import LoadingView from '@/components/static/loading-view';
import {PrivacyUpdatePersonalDataChild} from '@/components/business/privacy/privacy-update-personal-data-child';
import {PrivacyUpdatePersonalDataCaregiver} from '@/components/business/privacy/privacy-update-personal-data-caregiver';
import {InfoMessage} from '@/components/form/info-message';
import {PrivacyDeletePersonalData} from '@/components/business/privacy/privacy-delete-personal-data';
import {BackendApiService} from '@/services/backend-api-service';
import {colorPalette, medias} from '@/themes/darkmode';
import {getI18nLanguageKey} from '@/util/language-util';

class PersonalDataUpdatePage extends React.Component {
    static propTypes = {
        className: PropTypes.string,
        t: PropTypes.func,
        i18n: PropTypes.object,
        navigate: PropTypes.func,
    };

    state = {
        loading: false,
        tokenValid: false,
        personalData: null,
        personalDataDeleted: false,
        activeStep: 0,
    };
    
    constructor(props) {
        super(props);
        autoBind(this);
    }

    componentDidMount() {
        this.isTokenValid();
    }

    render() {
        const props = this.props;
        const state = this.state;
        const { t } = this.props;
        
        const steps = [
            t(['Child', 'pages.privacy.personalDataUpdate.steps.child']),
            t(['Caregiver', 'pages.privacy.personalDataUpdate.steps.caregiver']),
            t(['Delete my account', 'pages.privacy.personalDataUpdate.steps.deleteAccount']),
        ];

        return (
            <div className={props.className}>
                <ErrorBoundary>
                    {!state.loading && !state.personalDataDeleted && state.personalData &&
                        <div className="sections-wrapper">
                            <nav className="sections-menu">
                                {steps.map((step, i) => (
                                    <a key={i} className={(state.activeStep === i) ? `section-menu-step-${i} activeStep` : `section-menu-step-${i}`} onClick={() => this.handleStepClicked(i)}>{step}</a>
                                ))}
                            </nav>

                            <div className="sections-content">
                                <PrivacyUpdatePersonalDataChild className={(state.activeStep === 0) ? 'section-step-0 active' : 'section-step-0 hidden'} patientData={state.personalData.patientData} />
                                <PrivacyUpdatePersonalDataCaregiver className={(state.activeStep === 1) ? 'section-step-1 active' : 'section-step-1 hidden'} patientData={state.personalData.patientData} caregiverData={state.personalData.caregiverData} onSaved={this.handleSaved}/>
                                <PrivacyDeletePersonalData className={(state.activeStep === 2) ? 'section-step-2 active' : 'section-step-2 hidden'} onDeleted={this.handlePersonalDataDeleted} />
                            </div>
                        </div>
                    }
                    {state.personalDataDeleted &&
                        <InfoMessage infoMessage={t(['Your account has been successfully deleted.', 'pages.privacy.personalDataUpdate.info'])} text={t(['You cannot access your account anymore.', 'pages.privacy.personalDataUpdate.infoText'])} />
                    }
                    {state.loading && !state.personalDataDeleted &&
                        <LoadingView />
                    }
                </ErrorBoundary>
            </div>
        );
    }

    handleStepClicked(stepIndex) {
        this.setState(state => ({
            ...state,
            activeStep: stepIndex,
        }));
    }
    
    async handleSaved(caregiverData) {
        await this.updateAppLanguage(caregiverData);
    }

    handlePersonalDataDeleted() {
        this.setState(state => ({
            ...state,
            personalDataDeleted: true,
        }));
    }

    isTokenValid() {
        this.setState(state => ({
            ...state,
            loading: true,
        }));

        BackendApiService.getRequest({
            domain: 'privacy',
            modelName: 'privacyIsTokenValid',
        }).then((tokenValid) => {
            this.setState(state => ({
                ...state,
                tokenValid: tokenValid,
                loading: false,
            }));
            if (!tokenValid) {
                this.props.navigate('/personal-data-error');
            }
            else {
                this.fetchPersonalData();
            }
            return tokenValid;
        }).catch(err => {
            console.error(err);
            this.setState(state => ({
                ...state,
                loading: false,
            }));
        });
    }

    async fetchPersonalData() {
        this.setState(state => ({
            ...state,
            loading: true,
        }));

        try {
            const personalData = await BackendApiService.getRequest({
                domain: 'privacy',
                modelName: 'privacyPersonalData',
            });
            
            if (personalData && personalData.caregiverData) {
                await this.updateAppLanguage(personalData.caregiverData);
            }
            
            this.setState(state => ({
                ...state,
                personalData: personalData,
            }));
        }
        catch(err) {
            console.error(err);
            this.props.navigate('/personal-data-error');
        }
        finally {
            this.setState(state => ({
                ...state,
                loading: false,
            }));
        }
    }
    
    async updateAppLanguage(caregiverData) {
        if (caregiverData && caregiverData.preferredLanguage) {
            const i18nLng = getI18nLanguageKey(caregiverData.preferredLanguage);
            console.log('CHANGE LANGUAGE', caregiverData.preferredLanguage, i18nLng);
            await this.props.i18n.changeLanguage(i18nLng);
        }
    }

    shouldComponentUpdate(nextProps, nextState) {
        return !deepEqual(this.props, nextProps) || !deepEqual(this.state, nextState);
    }
}

//language=SCSS
PersonalDataUpdatePage = Styled(PersonalDataUpdatePage)`
& {
    min-height: 500px;
    width: 90%;
    height: calc(100% - 80px);
    margin: 150px auto 0 auto;

    .sections-menu {
        a {
            &.activeStep:last-child {
                background-color:${colorPalette.delete};
                border-color:${colorPalette.delete};
            }
            &:last-child:hover {
                background-color:${colorPalette.delete};
                border-color:${colorPalette.delete};
            }
        }
    }

    @media screen and (${medias.smMax}) {
        margin: 100px auto 0 auto;
    }
}
`;

export default withRouter(withTranslation()(PersonalDataUpdatePage));
